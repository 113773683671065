import axios from "axios";

const balanceGeneral = async function (data) {
    try {
        const response = await axios.post(`/api/balance-general-pdf`, data,{
            responseType: "blob"
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default balanceGeneral;