import axios from "axios";

const libroMayor= async function (data) {
    try {
        const response = await axios.post(`/api/libro-mayor`, data ,{
            responseType: "blob"
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default libroMayor;