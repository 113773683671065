import fetchAll from "./fetchAll";
import fetchAllAll from "./fetchAllAll";
import create from "./create";
import update from "./update";
import destroy from "./destroy";
import fetchFolio from "./fetchFolio";
import fetchAllFactura from "./fetchAllFactura";

export default {
    fetchAll, 
    fetchAllAll,
    create,
    update,
    destroy,
    fetchFolio,
    fetchAllFactura
}