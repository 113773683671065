import axios from "axios";

const destroy = async function(id) {
  try {
    const response = await axios.delete(`/api/delete_provacre/${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export default destroy;
