<template>
  <div>
    <v-dialog v-model="openDialog" max-width="1100px" persistent>
      <div class="d-flex flex-row-reverse">
        <v-btn class="mb-1" fab x-small color="red" dark @click="closeDialog"
          >X
        </v-btn>
      </div>
      <v-stepper v-model="ventana">
        <v-stepper-header>
          <v-stepper-step v-if="tipoFactura == 'compra'" step="1" editable>
            Datos Compra
          </v-stepper-step>
          <v-stepper-step v-if="tipoFactura == 'venta'" step="1" editable>
            Datos Venta
          </v-stepper-step>
          <v-stepper-step v-if="tipoFactura == 'boleta'" step="1" editable>
            Datos Boleta
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2"> Ingresa Asiento </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card>
              <v-row>
                <v-col cols="12" sm="8" md="9" lg="10" fluid>
                  <v-form ref="refFormFactura">
                    <v-row>
                      <v-col cols="12" sm="12" md="4" lg="3">
                        <v-text-field
                          v-model="fecha_factura"
                          name="fecha_factura"
                          label="Fecha Factura"
                          outlined
                          dense
                          type="date"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="tipoFactura == 'boleta'"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                      >
                        <v-text-field
                          v-model="desde_boleta"
                          name="desde_boleta"
                          label="Desde"
                          outlined
                          dense
                          type="number"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="tipoFactura == 'boleta'"
                        cols="12"
                        sm="12"
                        md="4"
                        lg="3"
                      >
                        <v-text-field
                          v-model="hasta_boleta"
                          name="hasta_boleta"
                          label="Hasta"
                          outlined
                          dense
                          type="number"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="tipoFactura != 'boleta'"
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                      >
                        <v-text-field
                          v-model="mes_declaracion"
                          name="mes_declaracion"
                          label="Mes Declaración"
                          outlined
                          dense
                          type="month"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="tipoFactura == 'compra'"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                      >
                        <v-autocomplete
                          label="Tipo Documento"
                          :items="itemsDocuemntoCompra"
                          item-text="nombre_tipo"
                          item-value="id"
                          return-object
                          v-model="tipo_documento"
                          outlined
                          dense
                          :rules="[rules.required]"
                          @change="resetExist"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        v-if="tipoFactura == 'venta'"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                      >
                        <v-autocomplete
                          label="Tipo Documento"
                          :items="itemsDocuemntoVenta"
                          item-text="nombre_tipo"
                          item-value="id"
                          return-object
                          v-model="tipo_documento"
                          outlined
                          dense
                          :rules="[rules.required]"
                          @change="resetExist"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        v-if="tipoFactura == 'compra'"
                        cols="12"
                        sm="8"
                        md="6"
                        lg="4"
                      >
                        <v-autocomplete
                          label="Rut - Nombre proveedor"
                          :items="itemsRut"
                          item-text="rut_nombre"
                          item-value="id"
                          v-model="rut_proveedor"
                          outlined
                          dense
                          :rules="[rules.required, rules.register]"
                          :error-messages="ndocRegister"
                        ></v-autocomplete>
                      </v-col>
                      <v-tooltip
                        color="green"
                        top
                        v-if="tipoFactura == 'compra'"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            small
                            class="mt-4"
                            elevation="5"
                            color="green"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            @click="openAdd"
                          >
                            <v-icon dark> mdi-plus </v-icon></v-btn
                          >
                        </template>
                        <span>Agregar Proveedor</span>
                      </v-tooltip>
                      <IngresaProvAcre
                        ref="refDialogIngresaProvAcre"
                        @successResponse="successResponse"
                        @errorResponse="errorResponse"
                      />
                      <v-col
                        v-if="tipoFactura == 'venta'"
                        cols="12"
                        sm="8"
                        md="6"
                        lg="4"
                      >
                        <v-autocomplete
                          label="Rut - Nombre acreedor"
                          :items="itemsRut"
                          item-text="rut_nombre"
                          item-value="id"
                          v-model="rut_proveedor"
                          outlined
                          dense
                          :rules="[rules.required, rules.register]"
                          :error-messages="ndocRegister"
                        ></v-autocomplete>
                      </v-col>
                      <v-tooltip
                        color="green"
                        top
                        v-if="tipoFactura == 'venta'"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            small
                            class="mt-4"
                            elevation="5"
                            color="green"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            @click="openAdd"
                          >
                            <v-icon dark> mdi-plus </v-icon></v-btn
                          >
                        </template>
                        <span>Agregar Acreedor</span>
                      </v-tooltip>
                      <v-col
                        v-if="tipoFactura != 'boleta'"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                      >
                        <v-text-field
                          :disabled="!rut_proveedor"
                          v-model="numero_documento"
                          name="numero_documento"
                          label="N° Documento"
                          outlined
                          dense
                          type="number"
                          :rules="[rules.required, rules.register]"
                          :error-messages="ndocRegister"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="3">
                        <v-text-field
                          v-model="neto"
                          name="neto"
                          label="Neto"
                          outlined
                          dense
                          @change="calculoIva()"
                          type="number"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="3">
                        <v-text-field
                          v-model="iva"
                          name="iva"
                          label="Iva"
                          outlined
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="3" v-if="showExento">
                        <v-text-field
                          v-model="exento"
                          name="exento"
                          label="Exento"
                          outlined
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="tipoFactura != 'boleta' && showIlla"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                      >
                        <v-text-field
                          v-model="ila"
                          name="ila"
                          label="Ila"
                          outlined
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="tipoFactura != 'boleta' && showEspecifico"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                      >
                        <v-text-field
                          v-model="especifico"
                          name="especifico"
                          label="Especifico"
                          outlined
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="tipoFactura != 'boleta' && showOtros"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                      >
                        <v-text-field
                          v-model="otros_impuestos"
                          name="otros_impuestos"
                          label="Otros Impuestos"
                          outlined
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="tipoFactura == 'venta' && showEmpConst"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                      >
                        <v-text-field
                          v-model="emp_const"
                          name="emp_const"
                          label="Empresa Constructora"
                          outlined
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>

                <v-col cols="12" sm="4" md="3" lg="2">
                  <v-card elevation="6">
                    <v-card-title primary-title> Impuestos </v-card-title>
                    <v-card-text>
                      <v-chip-group column multiple>
                        <v-chip
                          filter
                          outlined
                          @click="showExento = !showExento"
                        >
                          Exento
                        </v-chip>
                        <v-chip filter outlined @click="showIlla = !showIlla">
                          Illa
                        </v-chip>
                        <v-chip
                          filter
                          outlined
                          @click="showEspecifico = !showEspecifico"
                        >
                          Especifico
                        </v-chip>
                        <v-chip filter outlined @click="showOtros = !showOtros">
                          Otros Impuestos
                        </v-chip>
                        <v-chip v-if="tipoFactura == 'venta'" filter outlined @click="showEmpConst = !showEmpConst" >
                          Emp. Const.
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <v-row>
                <v-col class="ml-3" cols="2">
                  <v-switch
                    label="Con Tratamiento"
                    v-model="switch1"
                    @click="cleanTratamiento"
                  ></v-switch>
                </v-col>
                <v-col
                  class="mt-2"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  v-if="switch1"
                >
                  <v-select
                    v-model="tratamiento"
                    :items="items"
                    item-value="id"
                    item-text="nombre_tratamiento"
                    outlined
                    multiple
                    chips
                    label="Tratamientos"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn color="primary" @click="saveFactura"> Continuar </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2">
            <Asiento
              :DatosFactura="datos"
              :openDialog="openDialog"
              @snackbarVoucher="snackbarVoucher"
              @closeVoucher="closeDialog"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
    <SnackbarError
      :messageSnackbar="messageSnackbar"
      @successResponse="errorResponse"
      ref="refSnackbarError"
    />
  </div>
</template>
<script>
import SnackbarError from "../../../components/Snackbar/SnackbarError";
import Asiento from "../../../components/IngresaAsiento";
import IngresaProvAcre from "../../Proveedor-Acreedor/dialog/IngresaProvAcre";
import ProvedorAcreedorService from "../../../service/api/proveedorAcreedor/index";
import TipoDocumentoService from "../../../service/api/tipoDocumento/index";
import TratamientoService from "../../../service/api/tratamiento/index";
export default {
  name: "ingresa_factura",
  components: {
    Asiento,
    IngresaProvAcre,
    SnackbarError,
  },
  props: {
    tipoFactura: {
      type: String,
      default: "",
    },
  },
  computed: {
    validateForm() {
      return this.$refs.refFormFactura.validate();
    },
    resetForm() {
      return this.$refs.refFormFactura.reset();
    },
  },
  data() {
    return {
      messageSnackbar: "",
      esRegistrado: false,
      switch1: false,
      tratamiento: [],
      showIlla: false,
      showExento: false,
      showEspecifico: false,
      showOtros: false,
      showEmpConst: false,
      itemsDocuemntoCompra: [],
      itemsDocuemntoVenta: [],
      itemsRut: [],
      itemsNombre: [],
      openDialog: false,
      ventana: 1,
      fecha_factura: "",
      mes_declaracion: "",
      tipo_documento: "",
      rut_proveedor: "",
      nombre_proveedor: "",
      numero_documento: "",
      neto: 0,
      iva: 0,
      exento: 0,
      ila: 0,
      especifico: 0,
      otros_impuestos: 0,
      emp_const: 0,
      desde_boleta: "",
      hasta_boleta: "",
      datos: [],
      ndocRegister: [],
      rules: {
        required: (value) => !!value || "Requerido.",
        emailRule: (value) =>
          /.+@.+\..+/.test(value) || "Correo electrónico inválido.",
        minCharactersPassword: (value) =>
          value.length >= 6 || "Mínimo de 6 caracteres.",
        register: () => !this.esRegistrado,
      },
    };
  },
 
  methods: {
    openAdd() {
      this.$refs.refDialogIngresaProvAcre.openDialog = true;
    },
    successResponse() {
      this.getProvedorAcreedor();
    },
    errorResponse() {
      this.$refs.refSnackbarError.openSnackbar = true;
      this.messageSnackbar = `Error!! El rut ingresado, ya está registrado`;
    },
    obtenerFecha() {
      this.mes_declaracion = this.$store.state.mes;
      let new_fecha = this.$store.state.mes + "-01";
      this.fecha_factura = new_fecha;
    },
    saveFactura() {
      this.datos = [];
      this.datos.push({
        fecha_factura: this.fecha_factura,
        mes_declaracion: this.mes_declaracion,
        tipo_documento: this.tipo_documento.id,
        rut_proveedor: this.rut_proveedor,
        numero_documento: this.numero_documento,
        neto: this.neto,
        iva: this.iva,
        exento: this.exento,
        ila: this.ila,
        especifico: this.especifico,
        otros_impuestos: this.otros_impuestos,
        emp_const: this.emp_const,
        desde: this.desde_boleta,
        hasta: this.hasta_boleta,
        tratamientos: this.tratamiento,
        tipo: this.tipoFactura,
        id_empresa: parseInt(this.$store.state.empresa),
      });

      this.validateForm;
      if (this.validateForm) {
        this.ventana = 2;
      }
    },
    closeDialog() {
      this.resetForm;
      this.ventana = 1;
      this.datos = [];
      this.tipo_documento = "";
      this.tratamiento = [];
      this.desde_boleta = "";
      this.hasta_boleta = "";
      this.switch1 = false;
      this.openDialog = false;
    },
    calculoIva() {
      this.iva = Math.round(this.neto * 0.19);
      if(this.showEmpConst == true){
        this.emp_const = Math.round(this.iva * 0.65);
      }
    },

    async getTratamientos() {
      let response = await TratamientoService.fetchCuenta();
      this.items = response;
    },
    async getTipoDocumento() {
      let response = await TipoDocumentoService.fetchAll();
      this.itemsDocuemntoCompra = response;
      this.itemsDocuemntoVenta = response.filter(tipo => tipo.id != 6);
    },
    async getProvedorAcreedor() {
      let empresa = this.$store.state.empresa;
      let response = await ProvedorAcreedorService.fetchAllFactura(empresa);
      if (this.tipoFactura == "compra") {
        this.itemsRut = response.proveedores.map((resp) => {
          resp.rut_nombre = `${resp.rut} -- ${resp.nombre}`;
          return resp;
        });
      }
      if (this.tipoFactura == "venta") {
        this.itemsRut = response.acreedores.map((resp) => {
          resp.rut_nombre = `${resp.rut} -- ${resp.nombre}`;
          return resp;
        });
      }
    },
    snackbarVoucher(value) {
      this.$emit("snackbarVoucher", value);
    },
    cleanTratamiento() {
      if (this.tratamiento) {
        this.tratamiento = [];
      }
    },
    resetExist() {
      this.rut_proveedor = [];
      this.numero_documento = [];
    },
  },
  watch: {
   
    async numero_documento(val) {
      this.esRegistrado = false;
      let data = {};
      data.numero_documento = parseInt(val);
      data.id_provacre = this.rut_proveedor;
      data.tipo_factura = this.tipoFactura;
      data.id_empresa = this.$store.state.empresa;
      data.tipo_documento = this.tipo_documento.id;
      let response = await ProvedorAcreedorService.fetchFolio(data);
      if (response.data.id) {
        this.esRegistrado = true;
        return (this.ndocRegister = `Documento ya existe`);
      } else {
        this.esRegistrado = false;
        return (this.ndocRegister = []);
      }
    },
    openDialog(val) {
      if (val) {
        this.getTratamientos();
        this.getTipoDocumento();
        this.getProvedorAcreedor();
        this.obtenerFecha();
      }
    },
    showIlla(val) {
      if (!val) {
        this.ila = 0;
      }
    },
    showExento(val) {
      if (!val) {
        this.exento = 0;
      }
    },
    showEspecifico(val) {
      if (!val) {
        this.especifico = 0;
      }
    },
    showOtros(val) {
      if (!val) {
        this.otros_impuestos = 0;
      }
    },
    showEmpConst(val) {
      if (!val) {
        this.emp_const = 0;
      }
    },
  },
};
</script>
