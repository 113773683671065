export default [
    {
        title: 'Empresas',
        icon: 'mdi-office-building',
        path: '/empresa'
    },
    {
        title: 'Proveedor-Acreedor',
        icon: 'mdi-account-multiple',
        path: '/proveedor-acreedor'
    },
    {
        title: 'Usuarios',
        icon: 'mdi-account',
        path: '/usuarios'
    },
]