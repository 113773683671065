import axios from "axios";

const update = async function(data, id) {
  try {
    const response = await axios.put(`/api/update_provacre/${id}`, data);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export default update;
