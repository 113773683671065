<template>
  <div>
    <v-container grid-list-xs>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3"> 
          <v-card
            color="blue"
            dark
            @click="balanceGeneral"
            hover
            elevation="6"
          >
            <v-card-title primary-title> Balance General </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-card color="blue" dark @click="libroMayor" hover elevation="6">
            <v-card-title primary-title> Libro Mayor </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <BalanceGeneral ref="refFormBalanceGeneral"/>
    <DatosLibroMayor ref="refFormDatosLibroMayor"/>
  </div>
</template>

<script>

import DatosLibroMayor from "./dialog/DatosLibroMayor";
import BalanceGeneral from "./dialog/BalanceGeneral";
export default {
  name: "Informes",
  components: {
    DatosLibroMayor,
    BalanceGeneral,
  },
  data() {
    return {};
  },
  methods: {
    balanceGeneral() {
      this.$refs.refFormBalanceGeneral.dialog = true;
    }, 
    libroMayor() {
      this.$refs.refFormDatosLibroMayor.openDialog = true;
    },
  },
};
</script>