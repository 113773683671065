import axios from "axios";

const fetchAll = async function () {
    try {
        const response = await axios.get(`/api/list_tratamiento_cuentas`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchAll;