import axios from "axios";

const fetchByLD = async function (empresa, data) {
    try {
        const response = await axios.post(`/api/list_by_ld/${empresa}`, data);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchByLD;