
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('user') || '',
    auth: false,
    token: localStorage.getItem('token') || '',
    empresa: localStorage.getItem('empresa') || '',
    mes: localStorage.getItem('mes') || '',
  },
  mutations: {
    SET_EMPRESA(state, datos){
      localStorage.setItem('empresa', datos.empresa)
      localStorage.setItem('mes', datos.mes)
      state.empresa = datos.empresa
      state.mes = datos.mes
    },
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('token', token)
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    },
    SET_USER(state, user) {
      state.user = user.id;
      localStorage.setItem('user', user.id)
      state.auth = Boolean(user.id);
    },
    SET_LOGOUT(state) {
      state.user = null
      state.token = null
      state.empresa = null
      state.mes = null

    }
  },
  actions: {
    async logout({ commit }) {
      let dato = {}
      dato.id = this.state.user
      await axios.post("api/logout", dato);
      commit("SET_LOGOUT");
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('empresa')
      localStorage.removeItem('mes')
      delete axios.defaults.headers.common.Authorization
    },
    async login({ dispatch, commit }, credential) {
      await axios.get("sanctum/csrf-cookie");
      let response = await axios.post("api/login", credential);
      if (response.data.access_token) {
        dispatch("getToken", response.data.access_token);
        commit("SET_USER", response.data.user);
      } else {
        commit("SET_LOGOUT");
      } 
    },
    async empresa({dispatch}, datos){
      if (datos) {
        return dispatch("getEmpresa", datos)
      }else{
        return 0;
      }
    } ,
    getEmpresa({ commit }, datos){
      if(datos){
        commit("SET_EMPRESA",datos)
      }
    },
    getToken({ commit }, token) {
      if (token) {
        commit("SET_TOKEN", token);
      }
    },
    getUser({ commit }) {
      axios.get("api/user")
        .then(res => {
          commit("SET_USER", res.data);
        }).catch((error) => {
          console.log(error)
        });
    }
  },
  getters: {
    isLogged: state => !!state.empresa,
    isUser: state => !!state.user
  },
  modules: {}
});
