import create from "./create";
import fetchAll from "./fetchAll";
import fetchByVoucher from "./fetchByVoucher";
import fetchBydate from "./fetchBydate";
import update from "./update";
import cancel from "./cancel";

export default {
    fetchAll,
    create,
    fetchByVoucher,
    fetchBydate,
    update,
    cancel
}