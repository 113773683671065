import axios from "axios";

const libroDiarioFactura = async function (data) {
    try {
        const response = await axios.post(`/api/libro-diario-factura-pdf`,data,{
            responseType: "blob"
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default libroDiarioFactura;