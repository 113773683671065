import axios from "axios";

const fetchByMonth = async function (cod_cuenta,numero,empresa) {
    try {
        const response = await axios.get(`/api/voucher_month/${cod_cuenta}/${numero}/${empresa}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchByMonth;