<template>
  <div>
    <v-dialog v-model="openDialog" max-width="1000px" persistent>
      <v-card>
        <v-toolbar color="teal lighten-2" dark>
          <v-toolbar-title class="ml-2"
            >Creación de Proveedor / Acreedor</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn class="mb-1" fab x-small color="red" dark @click="closeDialog"
            >X</v-btn
          >
        </v-toolbar>
        <v-container>
          <v-card>
            <v-form ref="refDialogIngresaEmpresa" class="pa-2">
              <v-row>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmRut"
                    label="Rut...(1333666-5)"
                    :rules="[
                      (v) => !!v || 'Requerido.',
                      (v) => validaRut(v) || 'Rut mal ingresado',
                    ]"
                  ></v-text-field>
                  <!-- <v-btn @click="validaRut"></v-btn> -->
                </v-col>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmNombre"
                    label="Nombre..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <template>
                    <v-radio-group v-model="vmProvAcre" mandatory>
                      <v-radio label="Proveedor" value="prove"></v-radio>
                      <v-radio label="Acreedor" value="acre"></v-radio>
                    </v-radio-group>
                  </template>
                </v-col>
                <v-col>
                  <template>
                    <v-radio-group v-model="vmTodasEmpre" mandatory>
                      <v-radio label="Para esta empresa" disabled value="esta"></v-radio>
                      <v-radio
                        label="Para todas"
                        value="todas"
                      ></v-radio> </v-radio-group
                  ></template>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <div class="text-center">
                <v-btn class="mt-2" 
                  @click="submitCreate" 
                  :disabled=" isloading" 
                  dark 
                  color="success"
                  >Crear</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProveedorAcreedorService from "../../../service/api/proveedorAcreedor/index";
export default {
  name: "dialog-create-provacre",
  // props: {},
  data: function () {
    return {
      isloading: false,
      openDialog: false,
      vmRut: "",
      vmNombre: "",
      vmProvAcre: null,
      vmTodasEmpre: null,
    };
  },
  computed: {
    validateForm() {
      return this.$refs.refDialogIngresaEmpresa.validate();
    },
    resetForm() {
      return this.$refs.refDialogIngresaEmpresa.reset();
    },
  },
  methods: {
    validaRut(rutCompleto) {
      if (rutCompleto) {
        rutCompleto = rutCompleto.replace("‐", "-");
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
        var tmp = rutCompleto.split("-");
        var digv = tmp[1];
        var rut = tmp[0];
        if (digv == "K") digv = "k";

        return this.dv(rut) == digv;
      }
    },

    dv(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },

    closeDialog() {
      this.openDialog = false;
      this.resetForm;
    },
    async submitCreate() {
      if (this.validateForm) {
        this.isloading = true;
        let data = {};
        data.rut = this.vmRut;
        data.nombre = this.vmNombre;
        if (this.vmProvAcre == "prove") {
          data.tipo = "p";
        }
        if (this.vmProvAcre == "acre") {
          data.tipo = "a";
        }
        if (this.vmTodasEmpre == "esta") {
          //se elige empresa que esta logeado
          data.id_empresa = parseInt(this.$store.state.empresa);
        } else {
          data.id_empresa = null;
        }
        let response = await ProveedorAcreedorService.create(data);
        this.isloading = false
        if (response.data === 500) {
          this.closeDialog();
          this.$emit("errorResponse");
        } else {
          this.closeDialog();
          this.$emit("successResponse");
        }
      }
    },
  },
};
</script>
