<template>
  <v-container>
     <h4>Ingresa</h4>
    <v-row justify="center" dense>
     
      <v-col cols="12" sm="6" md="4" lg="3">
        <div>
          <v-card color="blue" dark @click="ingresaAsiento">
            <v-card-title primary-title> Ingresa Asiento </v-card-title>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <div>
          <v-card color="indigo" dark  @click="ingresaCompra">
            <v-card-title primary-title> Ingresa Compra </v-card-title>
          </v-card>
        </div>
      </v-col> 
      <v-col cols="12" sm="6" md="4" lg="3">
        <div>
          <v-card color="deep-purple" dark @click="ingresaVenta">
            <v-card-title primary-title > Ingresa Venta </v-card-title>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3  ">
        <div>
          <v-card color="cyan" dark @click="ingresaBoleta">
            <v-card-title primary-title> Ingresa Boleta </v-card-title>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-5"></v-divider>
    <v-row>
      <v-col cols="12">
       <h4>Informes</h4> 
       <Informes />
      </v-col>
    </v-row>  
    <IngresaAsiento 
      ref="refDialogAsiento"
    />
    <IngresaFactura
      ref="refDialogFactura"
      :tipoFactura="factura"
      @snackbarVoucher="snackbarVoucher"
    />
    <div class="text-center">
      <v-snackbar v-model="snackbar" :timeout="timeout" color="green">
        <h3>{{ texto_snackbar }}</h3>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import IngresaAsiento from './dialog/IngresaAsiento'
import IngresaFactura from './dialog/IngresaFactura'
import Informes from '../Informes/Informes'
export default {
  name: "Inicio",
  components: {
    IngresaAsiento,
    IngresaFactura,
    Informes,
  },
  data() {
    return {
      snackbar: false,
      texto_snackbar: '',
      timeout: 2000,
      factura:'',
    }
  },
  
  methods: {
    ingresaAsiento(){
      this.$refs.refDialogAsiento.openDialog = true
    },
    ingresaCompra(){
      this.$refs.refDialogFactura.openDialog = true
      this.factura = 'compra'
    },
    ingresaVenta(){
      this.$refs.refDialogFactura.openDialog = true
      this.factura = 'venta'
    },
    ingresaBoleta(){
      this.$refs.refDialogFactura.openDialog = true
      this.factura = 'boleta'
    },
    snackbarVoucher(value) {
      this.snackbar = true;
      this.texto_snackbar = `Voucher ${value}, agregado Exitosamente`;
    },
  },
};
</script>
