<template>
  <div>
    <v-dialog v-model="openDialog" persistent max-width="1100px">
      <div class="d-flex flex-row-reverse mb-1">
        <v-btn fab x-small color="red" dark @click="closeDialog">X </v-btn>
      </div>
      <EditAsiento
        ref="refFormEditLibroAsiento"
        :openDialog="openDialog"
        :DatosVoucher="datos"
        @closeDialog="closeDialog"
        @snackbarVoucher="snackbarVoucher"
      />
    </v-dialog>
    <div class="text-center">
      <v-snackbar v-model="snackbar" :timeout="timeout" color="green">
        <h3>{{ texto_snackbar }}</h3>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import EditAsiento from "@/components/EditAsiento";
export default {
  name: "edit_libro_asiento",
  components: {
    EditAsiento,
  },
  data() {
    return {
      voucher_edit: 0,
      openDialog: false,
      snackbar: false,
      timeout: 2000,
      texto_snackbar: "",
      datos: [],
    };
  },
  props: {
    DatosLibro: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.getValuesLibro()
  },
  methods: {
    async getValuesLibro() {
      this.voucher_edit = this.DatosLibro
      if (this.DatosLibro > 0) {
        this.datos = this.voucher_edit;
      }
    },
    closeDialog() {
      this.openDialog = false;
    },

    snackbarVoucher(value) {
      this.snackbar = true;
      this.$emit("getValues");
      this.$emit("getVoucher", value);
      this.texto_snackbar = `Voucher ${value}, Editado Exitosamente`;
    },
  },

  watch: {
    DatosLibro(val) {
      if (val > 0) {
        this.getValuesLibro();
      }
    },
  },
};
</script>
