import axios from "axios";

const fetch_folio = async function (datos) {
    try {
        const response = await axios.post(`/api/list_folio`, datos);
        return response;
    } catch (error) {
        return error.response.data;
    }
};

export default fetch_folio;