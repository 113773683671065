import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios';


if (store.state.token) {
  store.dispatch("getToken", store.state.token)
  let datos = {}
  datos.empresa = store.state.empresa
  datos.mes = store.state.mes
  store.dispatch("getEmpresa", datos)
}

// axios.interceptors.response.use(function (response) {
//   return response
// }, function (error) {
//   console.log(error.response.data)
//   if (error.response.status == 401) {
//     localStorage.clear();
//   return this.$router.push("/login");  
//   }
//   return Promise.reject(error)
// })

// Local
// axios.defaults.baseURL = 'http://localhost:8000/';

// Produccion

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = 'https://contabiliza2back.cyvauditores.cl/'
//axios.defaults.baseURL = 'https://sdcb.zeialab.cl/'

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      window.localStorage.clear()
      return router.go('/login')
    }
  }
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
