<template>
  <div class="text-center">
    <v-snackbar
      v-model="openSnackbar"
      dark
      color="warning"
    >
      {{ messageSnackbar }}

      <template v-slot:action="{ attrs }">
        <v-row class="mx-auto text-center">
        <v-btn v-bind="attrs" dark class="ml-4" small color="red" @click="openSnackbar = false"
          >Cancelar</v-btn
        >
      </v-row>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
export default {
  name: "snackbar-error",
  props: {
    messageSnackbar: {
      type: String,
      default: () => "Error al crear",
    },
  },
  data: function() {
    return {
      openSnackbar: false,
    };
  },
};
</script>
