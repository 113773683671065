import axios from "axios";

const create = async function (datos) {
    try {
        const response = await axios.post(`/api/create_empresa`,datos);
        return response;
    } catch (error) {
        return error.response.data;
    }
};

export default create;