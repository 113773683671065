<template>
  <v-app>
    <div v-if="isLoggedIn" >
      <v-navigation-drawer
        app
        color="teal"
        dark
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        :mini-variant="minivariant"
        mobile-breakpoint
        floating
      >
        <v-list dense nav>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.path"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar
        app
        color="teal"
        dark
        dense
        :clipped-left="$vuetify.breakpoint.lgAndUp"
      >
        <div class="d-flex align-center"></div>
        <h4>ContaFacil</h4>
        <v-app-bar-nav-icon
          class="ml-10"
          @click.stop="minivariant = !minivariant"
        />
        <!-- <v-row align="end" justify="end">
          <v-col cols="3" v-if="$store.state.user">
            
          </v-col>
          <v-col cols="3" v-if="$store.state.empresa" >
            <v-btn dark text >
              <v-icon dark class="mr-2">mdi-home-city</v-icon> {{ empresa }}</v-btn
            >
          </v-col>
        </v-row> -->
        <v-spacer></v-spacer>
        <div v-if="$store.state.user">
          <v-btn dark text disabled>
            <v-icon dark class="mr-2">mdi-account-circle</v-icon>
            {{ user }}</v-btn
          >
        </div>
        <div class="text-center">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark v-bind="attrs" v-on="on" @click="cambioEmpresa = true" icon>
                <v-icon> mdi-cog </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-content class="text-center">
                    <v-list-item-subtitle>Empresa:</v-list-item-subtitle>
                    <v-list-item-title>{{ empresa }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content class="text-center">
                    <v-list-item-subtitle>Fecha:</v-list-item-subtitle>
                    <v-list-item-title>{{
                      $store.state.mes
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <div class="text-center" v-if="cambioEmpresa">
                <v-btn
                  small
                  text
                  color="warning"
                  @click="cambioEmpresa = !cambioEmpresa"
                  >Cambiar</v-btn
                >
              </div>
              <v-divider></v-divider>
              <div v-if="cambioEmpresa">
                <v-list>
                  <v-list-item
                    v-for="item in items_config"
                    :key="item.title"
                    link
                    :to="item.path"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
              <div v-else>
                <v-form ref="refFormEmpresa">
                  <v-row align="center" justify="center">
                    <v-col cols="10">
                      <v-autocomplete
                        dense
                        return-object
                        v-model="vm_empresa"
                        :items="todaEmpresa"
                        item-text="infoCompleta"
                        item-value="id"
                        outlined
                        label="Selecione Empresa"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="10">
                      <v-text-field
                        dense
                        outlined
                        v-model="mes"
                        label="Mes y año a trabajar"
                        type="month"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <div>
                      <v-btn text color="success" @click="changeEmpresa"
                        >Guardar</v-btn
                      >
                    </div>
                  </v-row>
                </v-form>
              </div>
            </v-card>
          </v-menu>
        </div>
        <div class="mr-5">
          <v-tooltip bottom color="red">
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark v-bind="attrs" v-on="on" icon @click="logout">
                <v-icon> mdi-power-settings</v-icon>
              </v-btn>
            </template>
            <span>Cerrar sesión</span>
          </v-tooltip>
        </div>
         <div>
          <v-snackbar v-model="snackbar" rounded  dark :timeout="2000" centered color="success" app absolute top>
            <h4> {{ text }} </h4>
          </v-snackbar>
        </div>
      </v-app-bar>

      <v-main>
        <router-view></router-view>
      </v-main>
      
      <loading :active.sync="isLoading" 
        :can-cancel="true" 
        :on-cancel="onCancel"
        :is-full-page="fullPage"></loading>
    
    </div>
    <div v-else>
      <Login  @successResponse="successResponse"/>
    </div>
  </v-app>
</template>

<script>
import empresaService from "./service/api/empresa/index";
import MenuItems from "./menu/MenuItem";
import MenuConfig from "./menu/MenuConfig";
import Login from "./components/Login/login";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios'
export default {
  name: "App",
  data: () => ({
    isLoading: false,
    fullPage: true,
    user: '',
    snackbar: false, 
    text: "",
    vm_empresa: "",
    mes: "",
    drawer: true,
    cambioEmpresa: true,
    items: MenuItems,
    items_config: MenuConfig,
    todaEmpresa: [],
    minivariant: false,
    empresa: "",
    menu: false,
    rules: {
      required: (value) => !!value || "Requerido.",
      emailRule: (value) =>
        /.+@.+\..+/.test(value) || "Correo electrónico inválido.",
      minCharactersPassword: (value) =>
        value.length >= 6 || "Mínimo de 6 caracteres.",
    },
  }),
  components: {
    Login,
    Loading
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLogged;
    },
    validateForm2() {
      return this.$refs.refFormEmpresa.validate();
    },
  },
  methods: {
    successResponse(){
      this.isLoading= true
      this.getUser();
      this.getEmpresa();
      setTimeout(() => {this.isLoading = false}, 2000)

    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        return this.$router.push("/login");
      });
    },
    async getEmpresa() {
      let empresa = await empresaService.fetchById(this.$store.state.empresa);
      this.empresa = empresa.razon_social;
    },
    async getValuEmpresa() {
      this.mes = this.$store.state.mes;
      this.vm_empresa = parseInt(this.$store.state.empresa);
      let response = await empresaService.fetchAll();
      this.todaEmpresa = response.map((resp) => {
        resp.infoCompleta = `${resp.rut} - ${resp.razon_social}`;
        return resp;
      });
    },
    changeEmpresa() {
      
      let selecEmpresa = 0
      if (this.vm_empresa.id) {
        selecEmpresa = this.vm_empresa.id
      } else {
        selecEmpresa = this.vm_empresa
      }
      if (selecEmpresa != parseInt(this.$store.state.empresa) || this.$store.state.mes != this.mes) {
      if (this.validateForm2) {
        this.isLoading = true;
        let datos = {};
          datos.empresa = selecEmpresa;
          datos.mes = this.mes;
        let response = this.$store.dispatch("empresa", datos);
        if (response != 0) {
          this.$router.push("/");
          this.cambioEmpresa = true;
          this.menu = false;
          this.snackbar = true;
          this.text = "Cambio exitoso";
          this.getEmpresa();
        }
        setTimeout(() => {this.isLoading = false}, 2000)
      }
      }else{
        this.cambioEmpresa = true;
        this.menu = false;
      }
    },

    async getUser(){ 
     await axios.get(`api/user/${this.$store.state.user}`)
        .then(res => {
          this.user = res.data.name
        }).catch((error) => {
          console.log(error)
        });
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getUser();
      this.getEmpresa();
    }    
  },
  watch: {
    isLoggedIn(val){
      if (!val) {
        this.$router.push('/login') 
      }
    },
    cambioEmpresa(val) {
      if (!val) {
        this.getValuEmpresa();
      }else{
        this.cambioEmpresa = true
      }
    },
  },
};
</script>
