import fetchAll from "./fetchAll";
import create from "./create";
import destroy from "./destroy"
import update from "./update"
import fetchByTratamiento from "./fetchByTratamiento"
import fetchByLD from "./fetchByLD"

export default {
    fetchAll,
    create,
    destroy,
    update,
    fetchByTratamiento,
    fetchByLD
}