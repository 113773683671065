
import balanceGeneral from "./balanceGeneral";
import libroDiarioFactura from "./libroDiarioFactura";
import libroMayor from "./libroMayor";
import hojaFoleadas from "./hojasFoleadas";
import libroDiarioFacturaEmpConst from "./libroDiarioFacturaEmpConst";
import libroFacturaCompra from "./libroFacturaCompra";

export default {
    balanceGeneral, 
    libroDiarioFactura,
    libroDiarioFacturaEmpConst,
    libroMayor,
    hojaFoleadas,
    libroFacturaCompra
}