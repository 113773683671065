<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      transition="dialog-transition"
      persistent
    >
      <div class="d-flex flex-row-reverse">
        <v-btn class="mb-1" fab x-small color="red" dark @click="closeDialog"
          >X
        </v-btn>
      </div>
      <v-card>
        <v-card-title primary-title> Configuración </v-card-title>
        <v-card-text>
          <v-form ref="refFormConfiguracion">
            <v-row>
              <v-col md="4">
                <v-text-field
                  v-model="date1"
                  label="Fecha Inicio"
                  outlined
                  type="month"
                  dense
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="date2"
                  label="Fecha Termino"
                  outlined
                  type="month"
                  dense
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-switch label="Foliado" v-model="seleccion"></v-switch>
              </v-col>
              <div v-if="seleccion">
                <v-col cols="12">
                  <v-text-field
                    v-model="desde"
                    label="Desde"
                    outlined
                    type="number"
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    v-model="hasta"
                    label="Hasta"
                    outlined
                    type="number"
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </div>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div v-if="!seleccion">
            <v-col cols="12">
              <v-btn color="purple" outlined @click="balanceGeneralPDF"
                >Generar PDF</v-btn
              >
            </v-col>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="6" class="text-center">
                <v-btn color="purple" outlined @click="balanceGeneralPDF"
                  >Generar PDF
                </v-btn>
              </v-col>
              <v-col cols="6" class="text-center">
                <v-btn color="purple" outlined @click="hojasFoleadasPDF"
                  >hojas foliadas
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <div>
      <v-dialog
        v-model="dialog2"
        persistent
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title primary-title> Generando PDF </v-card-title>
          <v-card-text>
            <div class="text-center">
              <v-progress-linear
                indeterminate
                color="teal"
                height="10"
                rounded
                striped
              ></v-progress-linear>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import GenerarPdf from "../../../service/api/generarPdf/index";
export default {
  data() {
    return {
      dialog2: false,
      menu1: false,
      menu2: false,
      date1: this.$store.state.mes,
      date2: this.$store.state.mes,
      dialog: false,
      mes: this.$store.state.mes,
      seleccion: false,
      desde: 0,
      hasta: 0,
      rules: {
        required: (value) => !!value || "Requerido.",
      },
    };
  },
  computed: {
    validateForm() {
      return this.$refs.refFormConfiguracion.validate();
    },
    resetForm() {
      return this.$refs.refFormConfiguracion.reset();
    },
  },
  methods: {
    async hojasFoleadasPDF() {
      if (this.validateForm) {
        this.dialog2 = true;
        let data = {};
        data.empresa = parseInt(this.$store.state.empresa);
        data.desde = this.desde;
        data.hasta = this.hasta;
        data.mes = this.$store.state.mes;
        data.date1 = this.date1;
        data.date2 = this.date2;
        let response = await GenerarPdf.hojaFoleadas(data);
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        this.dialog2 = false;
        return window.open(fileURL);
      }
    },

    async balanceGeneralPDF() {
      if (this.validateForm) {
        this.dialog2 = true;
        let data = {};
        data.user = this.$store.state.user;
        data.mes = this.$store.state.mes;
        data.hasta = this.hasta;
        data.desde = this.desde;
        data.date1 = this.date1;
        data.date2 = this.date2;
        data.empresa = parseInt(this.$store.state.empresa);
        let response = await GenerarPdf.balanceGeneral(data);
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
         this.dialog2 = false;
        return window.open(fileURL);
      }
    },
    closeDialog() {
      this.dialog = false;
      (this.mes = this.$store.state.mes), (this.desde = 0);
      this.hasta = 0;
      this.seleccion = false;
    },
  },
  watch: {
    seleccion(val) {
      if (!val) {
        this.desde = 0;
        this.hasta = 0;
      }
    },
  },
};
</script>