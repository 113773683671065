<template>
  <div>
    <v-card>
      <v-card-title primary-title>
        Asientos
        <!-- <v-spacer></v-spacer>
      <v-btn medium color="primary" disabled> Voucher : 100</v-btn> -->
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" md="3" lg="3">
            <v-form ref="refFormAsiento">
              <v-autocomplete
                name="codigo"
                return-object
                :items="items_cuentas"
                item-text="infoCompleta"
                item-value="id"
                outlined
                dense
                v-model="codigo"
                label="Seleccione Cuenta"
                :rules="[rules.required]"
              ></v-autocomplete>
              <v-radio-group class="ml-10 pt-0" v-model="debe_haber" dense row>
                <v-radio label="Debe" :value="1"></v-radio>
                <v-radio label="Haber" :value="2"></v-radio>
              </v-radio-group>
              <v-text-field
                v-model="fecha"
                dense
                label="Fecha"
                type="date"
                outlined
                :disabled="disabledFecha"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                name="valor"
                v-model="valor"
                dense
                label="Valor"
                type="number"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
              <v-textarea
                v-model="glosa"
                rows="1"
                auto-grow
                label="Glosa"
                dense
                outlined
                :rules="[rules.required]"
              ></v-textarea>
              <v-col class="text-center">
                <v-btn v-if="!editar" small color="success" @click="AddAsiento">
                  Agregar
                </v-btn>
                <v-btn v-else color="warning" small @click="SaveAsiento">
                  Editar
                </v-btn>
              </v-col>
            </v-form>
          </v-col>
          <v-col cols="12" sm="8" md="9" lg="9">
            <v-row>
              <v-col class="text-center">
                <h4>Total Debe: ${{ formatoNumeroMiles(totalDebe) }}</h4>
              </v-col>
              <v-col class="text-left">
                <h4>Total Haber: ${{ formatoNumeroMiles(totalHaber) }}</h4>
              </v-col>
              <v-col v-if="total != 0" class="text-left">
                <h4>Total: ${{ formatoNumeroMiles(total) }}</h4>
              </v-col>
            </v-row>
            <v-col>
              <v-data-table
                dense
                :headers="headers"
                :items="datos"
                item-key="id"
                class="elevation-10"
                hide-default-footer
                :items-per-page="50"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    color="warning"
                    class="mr-2"
                    @click="EditAsiento(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon small color="red" @click="DeleteAsiento(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
            <v-col class="text-center" v-if="total === 0">
              <v-btn
                v-if="
                  totalDebe === totalHaber && totalDebe != 0 && totalHaber != 0
                "
                :disabled="isloading"
                :loading=" isloading"
                small
                color="success"
                @click="SaveVoucher"
                >Guardar</v-btn
              >
            </v-col>
            <v-col class="text-center" v-else>
              <v-btn
                v-if="
                  totalDebe === total &&
                  totalHaber === total &&
                  totalDebe != 0 &&
                  totalHaber != 0
                "
                :disabled="isloading"
                :loading="isloading"
                small
                color="success"
                @click="SaveVoucher"
                >Guardar</v-btn
              >
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import LibroDiarioService from "../service/api/libroDiario/index";
import CuentasImputablesService from "../service/api/cuentasImputables/index";
export default {
  data: () => ({
    disabledFecha: false,
    isloading:false,
    total: 0,
    editar: false,
    voucher_edit: 0,
    id: 1,
    id_edit: "",
    codigo: [],
    nombre_cuenta: "",
    cod_largo: 0,
    nombre: "",
    debe_haber: "",
    fecha: '',
    valor: [],
    glosa: "",
    totalDebe: 0,
    totalHaber: 0,
    rules: {
      required: (value) => !!value || "Requerido.",
      emailRule: (value) =>
        /.+@.+\..+/.test(value) || "Correo electrónico inválido.",
      minCharactersPassword: (value) =>
        value.length >= 6 || "Mínimo de 6 caracteres.",
    },
    datos: [],
    items_cuentas: [],
    headers: [
      { text: "Cod", value: "codigo" },
      { text: "Nombre", value: "nombre" },
      { text: "Debe", value: "debe" },
      { text: "Haber", value: "haber" },
      { text: "Glosa", value: "glosa" },
      { text: "Acción", value: "actions", sortable: false },
    ],
  }),
  computed: {
    validateForm() {
      return this.$refs.refFormAsiento.validate();
    },
    resetForm() {
      return this.$refs.refFormAsiento.reset();
    },
  },
  props: {
    openDialog: {
      type: Boolean,
      default: true,
    },
    DatosFactura: {
      type: Array,
      default: null,
    },
    DatosVoucher: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.getCuentas();
    this.fecha = this.$store.state.mes + "-01";
  },
  methods: {
    formatoNumeroMiles(number) {
      if (number || number > 0) {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = "$1.";
        let arr = number.toString().split(".");
        arr[0] = arr[0].replace(exp, rep);
        return arr[1] ? arr.join(".") : arr[0];
      } else return 0;
    },
 
    async getCuentas() {
      let empresa = this.$store.state.empresa;
      let response = await CuentasImputablesService.fetchAll(0, empresa);
      this.items_cuentas = response.map((resp) => {
        resp.infoCompleta = `${resp.cod_largo} - ${resp.nombre_cuenta}`;
        return resp;
      });
    },

    close() {
      this.$emit("closeDialog");
    },
    AddAsiento() {
      if (this.validateForm) {
        let valorDebe = 0;
        let valorHaber = 0;
        let estado = "";
        let datos_factura = [];
        if (this.debe_haber == "1") {
          valorDebe = this.valor;
        } else {
          valorHaber = this.valor;
        }
        if (this.DatosFactura) {
          estado = this.DatosFactura[0].tipo;
          datos_factura = this.DatosFactura[0];
        } else {
          estado = "asiento";
        }

        this.datos.push({
          id: this.id,
          codigo: this.codigo.cod_largo,
          nombre: this.codigo.nombre_cuenta,
          id_cuenta: this.codigo.id,
          debe: Math.round(valorDebe),
          haber: Math.round(valorHaber),
          glosa: this.glosa,
          fecha: this.fecha,
          debe_haber: this.debe_haber,
          valor: this.valor,
          cuenta:this.codigo,
          estado: estado,
          datos_factura: datos_factura,
          id_empresa: parseInt(this.$store.state.empresa),
        });

        this.totalDebe += Math.round(valorDebe);
        this.totalHaber += Math.round(valorHaber);
        this.id++;
      }
      this.codigo = null;
      this.valor = null;
      this.disabledFecha = true
      if (this.debe_haber == 1) {
        this.debe_haber = 1;
      } else {
        this.debe_haber = 2;
      }
    },
    EditAsiento(item) {
      this.editar = true;
      this.codigo = item.cuenta;
      this.debe_haber = item.debe_haber;
      this.fecha = item.fecha;
      this.valor = item.valor;
      this.glosa = item.glosa;
      this.id_edit = item.id;
      // this.resetForm;
    },
    SaveAsiento() {
      if (this.validateForm) {
        let valorDebe = 0;
        let valorHaber = 0;

        if (this.debe_haber == "1") {
          valorDebe = this.valor;
        } else {
          valorHaber = this.valor;
        }
        this.datos.forEach((element) => {
          if (element.id == this.id_edit) {
            element.codigo = this.codigo.cod_largo;
            element.nombre = this.codigo.nombre_cuenta;
            element.id_cuenta = this.codigo.id;
            element.debe_haber = this.debe_haber;
            (element.debe = valorDebe),
              (element.haber = valorHaber),
              (element.fecha = this.fecha);
            element.valor = this.valor;
            element.glosa = this.glosa;
            element.id = this.id_edit;
            return element;
          }
        });
        this.totalDebe = 0;
        this.totalHaber = 0;
        this.datos.forEach((data) => {
          this.totalDebe += Math.round(data.debe);
          this.totalHaber += Math.round(data.haber);
        });
        this.valor = null;
        this.editar = false;
        this.codigo = null;
        if (this.debe_haber == 1) {
          this.debe_haber = 1;
        } else {
          this.debe_haber = 2;
        }
      }
    },
    DeleteAsiento(item) {
      this.datos.forEach(function (asiento, index, object) {
        if (asiento.id === item.id) {
          object.splice(index, 1);
        }
      });
      this.totalDebe = 0;
      this.totalHaber = 0;
      this.datos.forEach((data) => {
        this.totalDebe += Math.round(data.debe);
        this.totalHaber += Math.round(data.haber);
      });
      this.codigo = [];
      this.debe_haber = 1;
    },
    async SaveVoucher() {
      this.isloading= true
      let empresa = parseInt(this.$store.state.empresa);
      let response = await LibroDiarioService.create(this.datos, empresa);
      this.isloading=false
      this.$emit("snackbarVoucher", response.data);
      this.close();
      this.$emit("closeVoucher");
    },
    async getFacturaValue() {
      if (this.DatosFactura[0]) {
        this.datos = [];
        this.fecha = "";
        this.glosa = "";
        this.totalDebe = 0;
        this.totalHaber = 0;
        this.total = 0;
        let tratamiento = this.DatosFactura[0].tratamientos;
        let response = await CuentasImputablesService.fetchByTratamiento(
          tratamiento
        );
        //   let valorDebe = 0;
        //     let valorHaber = 0;

        //     if (this.debe_haber == "1") {
        //       valorDebe = this.valor;
        //     } else {
        //       valorHaber = this.valor;
        //     }
        for (let i = 0; i < response.length; i++) {
          for (let j = 0; j < response[i].length; j++) {
            let valorDebe = 0;
            let valorHaber = 0;
            let vglosa = "";
            let debeHaberNota = 0;

            if (
              response[i][j].debe_haber == 1 &&
              response[i][j].impuesto.id == 1
            ) {
              if (this.DatosFactura[0].tipo_documento == "2") {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosFactura[0].iva) / 100;
              } else {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosFactura[0].iva) / 100;
              }
            }
            if (
              response[i][j].debe_haber == 2 &&
              response[i][j].impuesto.id == 1
            ) {
              if (this.DatosFactura[0].tipo_documento == "2") {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosFactura[0].iva) / 100;
              } else {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosFactura[0].iva) / 100;
              }
            }
            if (
              response[i][j].debe_haber == 1 &&
              response[i][j].impuesto.id == 2
            ) {
              if (this.DatosFactura[0].tipo_documento == "2") {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosFactura[0].ila) / 100;
              } else {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosFactura[0].ila) / 100;
              }
            }
            if (
              response[i][j].debe_haber == 2 &&
              response[i][j].impuesto.id == 2
            ) {
              if (this.DatosFactura[0].tipo_documento == "2") {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosFactura[0].ila) / 100;
              } else {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosFactura[0].ila) / 100;
              }
            }
            if (
              response[i][j].debe_haber == 1 &&
              response[i][j].impuesto.id == 3
            ) {
              if (this.DatosFactura[0].tipo_documento == "2") {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosFactura[0].exento) /
                  100;
              } else {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosFactura[0].exento) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 2 &&
              response[i][j].impuesto.id == 3
            ) {
              if (this.DatosFactura[0].tipo_documento == "2") {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosFactura[0].exento) /
                  100;
              } else {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosFactura[0].exento) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 1 &&
              response[i][j].impuesto.id == 4
            ) {
              if (this.DatosFactura[0].tipo_documento == "2") {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje *
                    this.DatosFactura[0].especifico) /
                  100;
              } else {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje *
                    this.DatosFactura[0].especifico) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 2 &&
              response[i][j].impuesto.id == 4
            ) {
              if (this.DatosFactura[0].tipo_documento == "2") {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje *
                    this.DatosFactura[0].especifico) /
                  100;
              } else {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje *
                    this.DatosFactura[0].especifico) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 1 &&
              response[i][j].impuesto.id == 5
            ) {
              if (this.DatosFactura[0].tipo_documento == "2") {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje *
                    this.DatosFactura[0].otros_impuestos) /
                  100;
              } else {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje *
                    this.DatosFactura[0].otros_impuestos) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 2 &&
              response[i][j].impuesto.id == 5
            ) {
              if (this.DatosFactura[0].tipo_documento == "2") {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje *
                    this.DatosFactura[0].otros_impuestos) /
                  100;
              } else {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje *
                    this.DatosFactura[0].otros_impuestos) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 1 &&
              response[i][j].impuesto.id == 6
            ) {
              if (this.DatosFactura[0].tipo_documento == "2") {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje *
                    this.DatosFactura[0].emp_const) /
                  100;
              } else {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje *
                    this.DatosFactura[0].emp_const) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 2 &&
              response[i][j].impuesto.id == 6
            ) {
              if (this.DatosFactura[0].tipo_documento == "2") {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje *
                    this.DatosFactura[0].emp_const) /
                  100;
              } else {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje *
                    this.DatosFactura[0].emp_const) /
                  100;
              }
            }
            if (this.DatosFactura[0].tipo == "boleta") {
              vglosa = `Boleta: ${this.DatosFactura[0].desde} - ${this.DatosFactura[0].hasta}`;
            } else {
              vglosa = `Documento: ${this.DatosFactura[0].numero_documento}`;
            }

            this.datos.push({
              id: response[i][j].id,
              codigo: response[i][j].cod_largo,
              nombre: response[i][j].nombre_cuenta,
              desde: this.DatosFactura[0].desde,
              hasta: this.DatosFactura[0].hasta,
              debe: Math.round(valorDebe),
              haber: Math.round(valorHaber),
              glosa: vglosa,
              fecha: this.DatosFactura[0].fecha_factura,
              debe_haber: debeHaberNota,
              valor: valorDebe + valorHaber,
              cuenta: response[i][j],
              id_cuenta: response[i][j].id,
              estado: this.DatosFactura[0].tipo,
              datos_factura: this.DatosFactura[0],
              id_empresa: parseInt(this.$store.state.empresa),
            });
            this.fecha = this.DatosFactura[0].fecha_factura;
            debeHaberNota = 0;
            this.total =
              Math.round(this.DatosFactura[0].neto) +
              Math.round(this.DatosFactura[0].iva) +
              Math.round(this.DatosFactura[0].ila) +
              Math.round(this.DatosFactura[0].especifico) +
              Math.round(this.DatosFactura[0].exento) +
              Math.round(this.DatosFactura[0].otros_impuestos) 
              //- parseInt(this.DatosFactura[0].emp_const);
            this.totalDebe += Math.round(valorDebe);
            this.totalHaber += Math.round(valorHaber);
          }
        }
        this.fecha = this.DatosFactura[0].fecha_factura;
        if (this.DatosFactura[0].tipo == "boleta") {
          this.glosa = `Boleta: ${this.DatosFactura[0].desde} - ${this.DatosFactura[0].hasta}`;
        } else {
          this.glosa = `Documento: ${this.DatosFactura[0].numero_documento}`;
        }

        this.total =
          Math.round(this.DatosFactura[0].neto) +
          Math.round(this.DatosFactura[0].iva) +
          Math.round(this.DatosFactura[0].ila) +
          Math.round(this.DatosFactura[0].especifico) +
          Math.round(this.DatosFactura[0].exento) +
          Math.round(this.DatosFactura[0].otros_impuestos)
          //- parseInt(this.DatosFactura[0].emp_const);
      }
    },
  },
  watch: {
    openDialog(val) {
      if (!val) {
        this.datos = [];
        this.totalDebe = 0;
        this.totalHaber = 0;
        this.disabledFecha = false;
        this.resetForm;
      } else {
        this.fecha = this.$store.state.mes + "-01";
      }
    },
    DatosFactura(val) {
      if (val.length > 0) {
        this.valor = null;
        this.getFacturaValue();
      }
    },
    // DatosVoucher(val) {
    //   if (val > 0) {
    //     this.datos = []
    //     this.totalDebe = 0;
    //     this.totalHaber = 0;
    //     this.getValuesToEdit();
    //   }
    // },
  },
};
</script>
