<template>
  <v-container class="mt-10">
    <v-layout align-center justify-center fill-height class="mt-10">
      <v-card
        elevation="7"
        width="500px"
        color="blue-grey darken-3"
        dark
        class="mt-10"
        :loading="load"
      >
        <template slot="progress">
          <v-progress-linear
            color="blue"
            stream
            rounded
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title primary-title> Inicio de sesión </v-card-title>
        <v-window v-model="step">
          <v-window-item :value="1">
            <v-card-text>
              <v-divider></v-divider>
              <v-form ref="refFormLogin">
                <v-row align="center" justify="center">
                  <v-col cols="10">
                    <v-text-field
                      v-model="user.email"
                      name="email"
                      label="Correo"
                      dense
                      type="email"
                      outlined
                      filled
                      prepend-inner-icon="mdi-email"
                      :rules="[rules.required, rules.emailRule]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10">
                    <v-text-field
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      v-model="user.password"
                      name="password"
                      label="Contraseña"
                      dense
                      outlined
                      filled
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                      v-on:keyup.enter="emailPassword"
                      prepend-inner-icon="mdi-lock"
                      :rules="[rules.required, rules.minCharactersPassword]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider></v-divider>
              <v-layout justify-center class="mt-5">
                <v-btn rounded outlined @click="emailPassword">Continuar</v-btn>
              </v-layout>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="2">
            <v-card-text>
              <v-divider></v-divider>
              <v-form ref="refFormEmpresa">
                <v-row align="center" justify="center">
                  <v-col cols="10">
                    <v-autocomplete
                      return-object
                      v-model="empresa"
                      :items="items"
                      item-text="infoCompleta"
                      item-value="id"
                      outlined
                      chips
                      small-chips
                      label="Selecione Empresa"
                      :rules="[rules.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                      dark
                      v-model="mes"
                      label="Mes y año a trabajar"
                      type="month"
                      :rules="[rules.required]"
                      v-on:keyup.enter="empresaFecha"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider></v-divider>
              <v-layout justify-center class="mt-5">
                <v-btn rounded outlined @click="empresaFecha">Ingresar</v-btn>
              </v-layout>
            </v-card-text>
          </v-window-item>
        </v-window>
      </v-card>
    </v-layout>
    <v-snackbar v-model="snackbar">
      Usuario mal ingresado.
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import EmpresaService from '../../service/api/empresa/index'
export default {
  name: "login",

  data: () => ({
    mes: "",
    empresa: "",
    step: 1,
    show1: false,
    load: false,
    snackbar: false,
    token: 0,
    data: {},
    user: {
      name: "",
      email: "",
      password: "",
    },
    rules: {
      required: (value) => !!value || "Requerido.",
      emailRule: (value) =>
        /.+@.+\..+/.test(value) || "Correo electrónico inválido.",
      minCharactersPassword: (value) =>
        value.length >= 6 || "Mínimo de 6 caracteres.",
    },
    items: [],
    value: null,
  }),
  computed: {
    isUserIn() {
      return this.$store.getters.isUser;
    },
    validateForm() {
      return this.$refs.refFormLogin.validate();
    },
    validateForm2() {
      return this.$refs.refFormEmpresa.validate();
    },
  },
  methods: {
    async emailPassword() {
      this.load = true;
      if (this.validateForm) {
        await this.$store.dispatch("login", this.user);
        if (this.isUserIn) {
          this.step = 2;
          this.getValuEmpresa()
          this.load = false;
        } else {
          this.snackbar = true;
          this.load = false;
        }
      }
      this.load = false;
    },
    empresaFecha() {
      this.load = true;
      if (this.validateForm2) {
        let datos = {}
        datos.empresa = this.empresa.id
        datos.mes = this.mes
         let response = this.$store.dispatch("empresa", datos);
        if (response != 0) {
          this.load = false;
          this.$emit('successResponse')
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.load = false;
        } 
        this.load = false;
      }
    },
    async getValuEmpresa(){
      let response =await EmpresaService.fetchAll()
      this.items = response.map(resp => {
        resp.infoCompleta = `${resp.rut} - ${resp.razon_social}`
        return resp
      })
    }
  },
};
</script>