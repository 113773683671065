<template>
  <div>
    <v-dialog v-model="openDialog" persistent max-width="1100px">
      <div class="d-flex flex-row-reverse mb-1">
        <v-btn fab x-small color="red" dark @click="closeDialog">X </v-btn>
      </div>
      <Asiento
        ref="refFormAsiento"
        :openDialog="openDialog"
        @closeDialog="closeDialog"
        @snackbarVoucher="snackbarVoucher"
      />
    </v-dialog>
    <div class="text-center">
      <v-snackbar v-model="snackbar" :timeout="timeout" color="green">
        <h3>{{ texto_snackbar }}</h3>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import Asiento from "@/components/IngresaAsiento";
export default {
  name: "ingresa_asiento",
  components: {
    Asiento,
  },
  data() {
    return {
      openDialog: false,
      snackbar: false,
      timeout: 2000,
      texto_snackbar: "",
    };
  },
  methods: {
    closeDialog() {
      this.openDialog = false;
    },
    
    snackbarVoucher(value) {
      this.snackbar = true;
      this.texto_snackbar = `Voucher ${value}, Guardado Exitosamente`;
    },
  },
};
</script>
