import axios from "axios";

const fetchBydateCode = async function (data) {
    try {
        const response = await axios.post(`/api/voucher_cuenta`, data);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchBydateCode;