import axios from "axios";

const fetchByTratamiento = async function (tratamiento) {
    try {
        const response = await axios.post(`/api/list_cuentatratamiento`,tratamiento);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchByTratamiento;