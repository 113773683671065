import fetchAll from "./fetchAll";
import create from "./create";
import update from "./update";
import destroy from "./destroy";
import fetchById from "./fetchById";

export default {
    fetchAll,
    create,
    update,
    destroy,
    fetchById
}