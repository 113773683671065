<template>
  <div>
    <v-dialog
      v-model="openDialog"
      max-width="1000px"
      persistent
      v-if="!showDetail"
    >
      <v-card>
        <v-toolbar color="teal lighten-2" dark>
          <v-toolbar-title class="ml-2">Datos Libro Mayor</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mb-1" fab x-small color="red" dark @click="closeDialog"
            >X</v-btn
          >
        </v-toolbar>
        <v-container>
          <v-card>
            <v-form ref="refDialogSearchAccount" class="pa-2">
              <v-row>
                <v-col md="4">
                  <v-text-field
                    dense
                    outlined
                    v-model="vmDesde"
                    label="Desde"
                    type="month"
                    @change="getCuentas"
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    dense
                    outlined
                    v-model="vmHasta"
                    label="Hasta"
                    type="month"
                    @change="getCuentas"
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-autocomplete
                    return-object
                    :items="items_cuentas"
                    item-text="infoCompleta"
                    item-value="id"
                    outlined
                    multiple
                    chips
                    dense
                    v-model="vmCodigo"
                    label="Seleccione Cuenta"
                    :rules="[(v) => !!v || null || 'Requerido.']"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="toggle">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              vmCodigo.length > 0 ? 'indigo darken-4' : ''
                            "
                          >
                            {{ icon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Seleccione Todos
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item disabled>
                        <v-list-item-avatar color="grey lighten-3">
                          <v-icon> mdi-food-apple </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content v-if="likesAllFruit">
                          <v-list-item-title>
                            Todos Seleccionados !!!
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-content v-else-if="likesSomeFruit">
                          <v-list-item-title>
                            Agunos seleccionados
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ vmCodigo.length }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-content v-else>
                          <v-list-item-title>
                            Ninguno Seleccionado
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Debe Elegir Alguno
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              
              </v-row>
              <div class="text-center">
                <v-btn class="mt-2" @click="searchAccount" dark color="info"
                  >Buscar</v-btn
                >
              </div>
            </v-form>
            <v-divider></v-divider>
              <div class="text-center" v-if="showResults">
                <v-list-item>
                  <v-list-item-content>
                    <v-form ref="refFormConfiguracion">
                      <v-row>
                        <v-col cols="4">
                          <v-switch
                            label="Foliado"
                            v-model="seleccion"
                          ></v-switch>
                        </v-col>
                        <v-col col="4">
                          <div v-if="seleccion">
                            <v-col cols="6">
                              <v-text-field
                                v-model="inicio"
                                label="Desde"
                                outlined
                                type="number"
                                dense
                                :rules="[rules.required]"
                              ></v-text-field>
                              <v-text-field
                                v-model="fin"
                                label="Hasta"
                                outlined
                                type="number"
                                dense
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-list-item-content>
                  <v-list-item-content v-if="!seleccion">
                    <v-btn color="purple" outlined @click="LibroMayorPDF"
                      >Generar PDF</v-btn
                    >
                  </v-list-item-content>
                  <v-list-item-content v-else>
                    <v-col cols="6">
                      <v-btn color="purple" outlined @click="LibroMayorPDF"
                        >Hojas Foliadas</v-btn
                      >
                    </v-col>
                    <v-col cols="6">
                      <v-btn color="purple" outlined @click="hojasFoleadasPDF"
                        >PDF
                      </v-btn>
                    </v-col>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <v-divider></v-divider>
            <v-container>
              <div v-if="showResults">
                <v-row>
                  <v-col md="4">
                    <h4></h4>
                  </v-col>
                  <v-col md="4" class="font-weight-bold"> DEBE </v-col>
                  <v-col md="4" class="font-weight-bold"> HABER</v-col>
                </v-row>
                <v-row>
                  <v-col md="4">
                    <h4>SUB TOTALES</h4>
                  </v-col>
                  <v-col md="4"> ${{ sumaDebe }} </v-col>
                  <v-col md="4"> ${{ sumaHaber }} </v-col>
                </v-row>
                <v-row>
                  <v-col md="4">
                    <h4>Saldo {{ saldo }}</h4>
                  </v-col>
                  <v-col md="4"> ${{ this.diffHaber }} </v-col>
                  <v-col md="4"> ${{ this.diffDebe }} </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  <v-col md="4">
                    <h4>TOTALES</h4>
                  </v-col>
                  <v-col md="4"> ${{ this.totHaber }} </v-col>
                  <v-col md="4"> ${{ this.totDebe }} </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row>
                  <v-col>{{ this.NoResults }}</v-col>
                </v-row>
              </div>
              <div v-if="showResults">
                <v-col v-for="cuenta in tipocuenta" :key="cuenta.id">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="cuenta"
                    no-data-text="Sin Contenido"
                    no-results-text="Sin resultados encontrados"
                    item-key="id"
                    class="elevation-10"
                    disable-pagination
                    hide-default-footer
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon
                        small
                        color="warning"
                        class="mr-2"
                        @click="editLibro(item.voucher, item.tipo_cuenta)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </div>
            </v-container>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog
        v-model="dialog2"
        persistent
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title primary-title> Generando PDF </v-card-title>
          <v-card-text>
            <div class="text-center">
              <v-progress-linear
                indeterminate
                color="teal"
                height="10"
                rounded
                striped
              ></v-progress-linear>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <EditLibroAsiento
        ref="refFormEditLibroAsiento"
        :DatosLibro="voucher"
        @getValues="searchAccount"
      />
    </div>
     <div>
      <EditFacturaAsiento
        ref="refDialogEditFactura"
        :voucher="voucher"
        @getValues="searchAccount"
      />
    </div>
  </div>
</template>

<script>
import GenerarPdf from "../../../service/api/generarPdf/index";
import LibroMayorService from "../../../service/api/libroMayor/index";
import CuentasImputablesService from "../../../service/api/cuentasImputables/index";

import EditLibroAsiento from "../../LibroDiario/dialog/EditLibroAsiento.vue";
import EditFacturaAsiento from "../../Inicio/dialog/EditaFactura.vue";

export default {
  name: "dialog-search-account",
  components:{
    EditLibroAsiento,
    EditFacturaAsiento
  },
  computed: {
    likesAllFruit() {
      return this.vmCodigo.length === this.items_cuentas.length;
    },
    likesSomeFruit() {
      return this.vmCodigo.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    validateForm() {
      return this.$refs.refDialogSearchAccount.validate();
    },
    validateFormFolio() {
      return this.$refs.refFormConfiguracion.validate();
    },
    resetForm() {
      return this.$refs.refDialogSearchAccount.reset();
    },
  },

  data() {
    return {
      tipocuenta: [],
      voucher: 0,
      cuentas: [],
      dialog2:false,
      inicio: 0,
      fin: 0,
      seleccion: false,
      debe_month: 0,
      haber_month: 0,
      items: [],
      panel: true,
      panel1: true,
      load: false,
      load1: false,
      asientos_debe: [],
      asientos_haber: [],
      headers: [
        { text: "Fecha", value: "mes_declaracion" },
        { text: "Voucher", value: "voucher" },
        { text: "Codigo Cuenta", value: "cuenta.cod_largo" },
        { text: "Tipo Cuenta", value: "tipo_cuenta" },
        { text: "Valor", value: "valor", align:'end'},
        { text: "Acción", value: "actions", sortable: false },
      ],
      total_debe: 0,
      total_haber: 0,
      items_cuentas: [],
      openDialog: false,
      openDialogDetail: false,
      vmDesde: "",
      vmHasta: "",
      vmCodigo: [],
      datos: [],
      sumaDebe: 0,
      sumaHaber: 0,
      showResults: false,
      NoResults: "",
      diffDebe: 0,
      diffHaber: 0,
      saldo: "",
      totDebe: 0,
      totHaber: 0,
      showDetail: false,
      meses: [],
      rules: {
        required: (value) => !!value || "Requerido.",
      },
    };
  },
  mounted() {
    this.getCuentas();
  },

  methods: {
    editLibro(voucher, tipocuenta) {
      if (tipocuenta == "asiento") {
        this.$refs.refFormEditLibroAsiento.openDialog = true;
      } else {
        this.$refs.refDialogEditFactura.openDialog = true;
      }
      this.voucher = voucher;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.vmCodigo = [];
        } else {
          this.vmCodigo = this.items_cuentas.slice();
        }
      });
    },
    async getCuentas() {
      let empresa = this.$store.state.empresa;
      let data = {};
      data.mes_desde = this.vmDesde;
      data.mes_hasta = this.vmHasta;

      let response = await CuentasImputablesService.fetchByLD(empresa,data);
      this.items_cuentas = response.map((resp) => {
        resp.infoCompleta = `${resp.cuenta.cod_largo} - ${resp.cuenta.nombre_cuenta}`;
        return resp;
      });
    },
    closeDialog() {
      this.openDialog = false;
      this.resetForm;
      this.showResults = false;
      this.NoResults = "";
    },
    closeDialogDetail() {
      this.openDialogDetail = false;
      this.showDetail = false;
    },
    async searchAccount() {
      if (this.validateForm) {
        this.cuentas = []
        let data = {};
        data.empresa = parseInt(this.$store.state.empresa);
        data.desde = this.vmDesde;
        data.hasta = this.vmHasta;
        data.codigo = this.vmCodigo;
        this.tipocuenta  = await LibroMayorService.voucherCuenta(data)
        
        let response = await LibroMayorService.fetchBydateCode(data);
        this.showResults = false;
        this.diffDebe = 0;
        this.diffHaber = 0;
        if (response) {
          if (response.suma_debe == 0 && response.suma_haber == 0) {
            this.showResults = false;
            this.NoResults = "No Hay Resultados";
          } else {
            this.showResults = true;
            this.sumaDebe = this.formatoNumeroMiles(response.suma_debe);
            this.sumaHaber = this.formatoNumeroMiles(response.suma_haber);
            if (response.diff_debe) {
              this.diffDebe = this.formatoNumeroMiles(response.diff_debe);
              this.saldo = "Deudor";
            }
            if (response.diff_haber) {
              this.diffHaber = this.formatoNumeroMiles(response.diff_haber);
              this.saldo = "Acreedor";
            }
            this.totDebe = this.formatoNumeroMiles(response.tot_debe);
            this.totHaber = this.formatoNumeroMiles(response.tot_haber);
          }
        }
      }
    },

    async hojasFoleadasPDF() {
      if (this.validateFormFolio) {
        this.dialog2 = true;
        let data = {};
        data.empresa = parseInt(this.$store.state.empresa);
        data.desde = this.inicio;
        data.hasta = this.fin;
        data.mes = this.mes;
        let response = await GenerarPdf.hojaFoleadas(data);
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        this.dialog2 = false;
        return window.open(fileURL);
      }
    },
    async LibroMayorPDF() {
      let data = {};
      this.dialog2 = true;
      data.empresa = parseInt(this.$store.state.empresa);
      data.usuario = this.$store.state.user;
      data.desde = this.vmDesde;
      data.hasta = this.vmHasta;
      data.inicio = this.inicio;
      data.fin = this.fin;
      data.codigo = this.vmCodigo;
      let response = await GenerarPdf.libroMayor(data);
      const file = new Blob([response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      this.dialog2 = false;
      return window.open(fileURL);
    },
    formatoNumeroMiles(number) {
      if (number || number > 0) {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = "$1.";
        let arr = number.toString().split(".");
        arr[0] = arr[0].replace(exp, rep);
        return arr[1] ? arr.join(".") : arr[0];
      } else return 0;
    },
  },
  watch: {
    seleccion(val) {
      if (!val) {
        this.inicio = 0;
        this.fin = 0;
      }
    },
  },
};
</script>
