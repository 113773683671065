import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from '../views/Inicio/Inicio.vue'
// import store from '../store';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/Login/login'),
    meta: {guest: true},
  },
  {
    path: '/cuentas_imputables',
    name: 'cuentas_imputables',
    component: () => import('../views/CuentasImputables/CuentasImputables'),
    meta: {requiresAuth: true},
  },
  {
    path: '/libro_diario',
    name: 'libro_diario',
    component: () => import('../views/LibroDiario/LibroDiario'),
    meta: {requiresAuth: true},
  },
  {
    path: '/libro_compra',
    name: 'libro_compra',
    component: () => import('../views/LibroCompra/LibroCompra'),
    meta: {requiresAuth: true},
  },
  {
    path: '/libro_venta',
    name: 'libro_venta',
    component: () => import('../views/LibroVenta/LibroVenta'),
    meta: {requiresAuth: true},
  },
  {
    path: '/libro_boleta',
    name: 'libro_boleta',
    component: () => import('../views/LibroBoleta/LibroBoleta'),
    meta: {requiresAuth: true},
  },
  {
    path: '/informes',
    name: 'informes',
    component: () => import('../views/Informes/Informes'),
    meta: {requiresAuth: true},
  },
  {
    path: '/empresa',
    name: 'empresa',
    component: () => import('../views/Empresa/Empresa'),
    meta: {requiresAuth: true},
  },
  {
    path: '/proveedor-acreedor',
    name: 'proveedor-acreedor',
    component: () => import('../views/Proveedor-Acreedor/Proveedor_Acreedor'),
    meta: {requiresAuth: true},
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('../views/Usuarios/Usuarios'),
    meta: {requiresAuth: true},
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
})

// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     if (store.getters.isAuthenticated) {
//       next()
//       return
//     }
//     next('/login')
//   } else {
//     next()
//   }
// })


export default router
