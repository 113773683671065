<template>
  <div>
    <v-card>
      <v-card-title primary-title>
        Asientos
        <!-- <v-spacer></v-spacer>
      <v-btn medium color="primary" disabled> Voucher : 100</v-btn> -->
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" md="3" lg="3">
            <v-form ref="refFormAsiento">
              <v-autocomplete
                return-object
                :items="items_cuentas"
                item-text="infoCompleta"
                item-value="id"
                outlined
                dense
                v-model="codigo"
                label="Seleccione Cuenta"
                :rules="[rules.required]"
              ></v-autocomplete>
              <v-radio-group class="ml-10 pt-0" v-model="debe_haber" dense row>
                <v-radio label="Debe" :value="1"></v-radio>
                <v-radio label="Haber" :value="2"></v-radio>
              </v-radio-group>
              <v-text-field
                v-model="fecha"
                name="fecha"
                dense
                label="Fecha"
                type="date"
                outlined
                disabled
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                v-model="valor"
                name="valor"
                dense
                label="Valor"
                type="number"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
              <v-textarea
                v-model="glosa"
                rows="1"
                auto-grow
                label="Glosa"
                name="glosa"
                dense
                outlined
                :rules="[rules.required]"
              ></v-textarea>
              <v-col class="text-center">
                <v-btn v-if="!editar" small color="success" @click="AddAsiento">
                  Agregar
                </v-btn>
                <v-btn v-else color="warning" small @click="SaveAsiento">
                  Editar
                </v-btn>
              </v-col>
            </v-form>
          </v-col>
          <v-col cols="12" sm="8" md="9" lg="9">
            <v-row>
              <v-col class="text-center">
                <h4>Total Debe: ${{ totalDebe }}</h4>
              </v-col>
              <v-col class="text-left">
                <h4>Total Haber: ${{ totalHaber }}</h4>
              </v-col>
              <v-col class="text-left" v-if="total != 0">
                <h4>Total : ${{ total }}</h4>
              </v-col>
            </v-row>
            <v-col>
              <v-data-table
                dense
                :headers="headers"
                :items="datos"
                item-key="id"
                class="elevation-10"
                hide-default-footer
                :items-per-page="30"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    color="warning"
                    class="mr-2"
                    @click="EditAsiento(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon small color="red" @click="DeleteAsiento(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
            <v-col class="text-center" v-if="total == 0">
              <v-btn
                v-if="
                  totalDebe == totalHaber && totalDebe != 0 && totalHaber != 0
                "
                small
                color="warning"
                @click="updateVoucher"
                :disabled="isloading"
                :loading="isloading"              
                >Guardar</v-btn
              >
            </v-col>
            <v-col class="text-center" v-else>
              <v-btn
                v-if="
                  totalDebe == total &&
                  totalHaber == total &&
                  totalDebe != 0 &&
                  totalHaber != 0
                "
                small
                color="warning"
                :disabled="isloading"
                :loading="isloading"
                @click="updateVoucher"
                >Guardar</v-btn
              >
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import LibroDiarioService from "../service/api/libroDiario/index";
import CuentasImputablesService from "../service/api/cuentasImputables/index";
export default {
  data: () => ({
    isloading:false,
    total: 0,
    editar: false,
    voucher_edit: 0,
    id: 1,
    id_edit: "",
    codigo: [],
    nombre_cuenta: "",
    cod_largo: 0,
    nombre: "",
    debe_haber: "",
    fecha: null,
    valor: null,
    glosa: "",
    totalDebe: 0,
    totalHaber: 0,
    rules: {
      required: (value) => !!value || "Requerido.",
      emailRule: (value) =>
        /.+@.+\..+/.test(value) || "Correo electrónico inválido.",
      minCharactersPassword: (value) =>
        value.length >= 6 || "Mínimo de 6 caracteres.",
    },
    datos: [],
    items_cuentas: [],
    headers: [
      { text: "Cod", value: "codigo" },
      { text: "Nombre", value: "nombre" },
      { text: "Debe", value: "debe" },
      { text: "Haber", value: "haber" },
      { text: "Glosa", value: "glosa" },
      { text: "Acción", value: "actions", sortable: false },
    ],
  }),
  computed: {
    validateForm() {
      return this.$refs.refFormAsiento.validate();
    },
    resetForm() {
      return this.$refs.refFormAsiento.reset();
    },
  },
  props: {
    openDialog: {
      type: Boolean,
      default: true,
    },
    DatosFactura: {
      type: Array,
      default: null,
    },
    DatosVoucher: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
      this.getValuesToEdit();
      this.getCuentas();
  },
  methods: {
    async getCuentas() {
      let empresa = parseInt(this.$store.state.empresa);
      let response = await CuentasImputablesService.fetchAll(0,empresa);

      this.items_cuentas = response.map((resp) => {
        resp.infoCompleta = `${resp.cod_largo} - ${resp.nombre_cuenta}`;
        return resp;
      });
    },

    close() {
      this.$emit("closeDialog");
    },
    AddAsiento() {
      if (this.validateForm) {
        let valorDebe = 0;
        let valorHaber = 0;

        if (this.debe_haber == "1") {
          valorDebe = this.valor;
        } else {
          valorHaber = this.valor;
        }
        this.datos.push({
          id: this.id,
          codigo: this.codigo.cod_largo,
          nombre: this.codigo.nombre_cuenta,
          id_cuenta: this.codigo.id,
          debe: Math.round(valorDebe),
          haber: Math.round(valorHaber),
          glosa: this.glosa,
          fecha: this.fecha,
          debe_haber: this.debe_haber,
          valor: this.valor,
          cuenta: this.codigo,
          id_empresa: this.$store.state.empresa
        });
        this.totalDebe += Math.round(valorDebe);
        this.totalHaber += Math.round(valorHaber);
        this.id++;
      }
      this.codigo = null;
      if (this.debe_haber == 1) {
        this.debe_haber = 2;
      } else {
        this.debe_haber = 1;
      }
    },
    EditAsiento(item) {
      this.editar = true;
      this.codigo = item.cuenta;
      this.debe_haber = item.debe_haber;
      this.fecha = item.fecha;
      this.valor = item.valor;
      this.glosa = item.glosa;
      this.id_edit = item.id;
    },
    SaveAsiento() {
      let valorDebe = 0;
      let valorHaber = 0;

      if (this.debe_haber == "1") {
        valorDebe = this.valor;
      } else {
        valorHaber = this.valor;
      }
      this.datos.forEach((element) => {
        if (element.id == this.id_edit) {
          element.codigo = this.codigo.cod_largo;
          element.nombre = this.codigo.nombre_cuenta;
          element.id_cuenta = this.codigo.id;
          element.debe_haber = this.debe_haber;
          (element.debe = valorDebe),
          (element.haber = valorHaber),
          (element.fecha = this.fecha);
          element.valor = this.valor;
          element.glosa = this.glosa;
          element.id = this.id_edit;
          return element;
        }
      });
      this.totalDebe = 0;
      this.totalHaber = 0;
      this.datos.forEach((data) => {
        this.totalDebe += Math.round(data.debe);
        this.totalHaber += Math.round(data.haber);
      });
      this.editar = false;
      this.codigo = [];
      this.valor = null;
    },
    DeleteAsiento(item) {
      this.datos.forEach(function (asiento, index, object) {
        if (asiento.id === item.id) {
          object.splice(index, 1);
        }
      });
      this.totalDebe = 0;
      this.totalHaber = 0;
      this.datos.forEach((data) => {
        this.totalDebe += Math.round(data.debe);
        this.totalHaber += Math.round(data.haber);
      });
    },
    async updateVoucher() {
      this.isloading=true
       await LibroDiarioService.update(
        this.datos,
        this.voucher_edit
      );
      this.$emit("snackbarVoucher", this.voucher_edit);
      this.close();
      this.isloading = false
      
    },
    async getValuesToEdit() {
      // this.resetForm()
      if (this.DatosVoucher > 0) {
        this.voucher_edit = this.DatosVoucher;
      }
      let empresa = parseInt(this.$store.state.empresa);
      let response = await LibroDiarioService.fetchByVoucher(this.voucher_edit, empresa);
      let valorDebe = 0;
      let valorHaber = 0;
      for (let i = 0; i < response.length; i++) {
        if (response[i].debe_haber == "1") {
          valorDebe = response[i].valor;
        } else {
          valorHaber = response[i].valor;
        }
        this.datos.push({
          id: response[i].id,
          codigo: response[i].cuenta.cod_largo,
          nombre: response[i].cuenta.nombre_cuenta,
          debe: Math.round(valorDebe),
          haber: Math.round(valorHaber),
          glosa: response[i].glosa,
          fecha: response[i].date,
          debe_haber: response[i].debe_haber,
          valor: response[i].valor,
          cuenta: response[i].cuenta,
          id_cuenta: response[i].cuenta.id,
          desde: response[i].desde,
          hasta: response[i].hasta,
          id_empresa: parseInt(this.$store.state.empresa)
        });
        this.totalDebe += Math.round(valorDebe);
        this.totalHaber += Math.round(valorHaber);
        valorDebe = 0;
        valorHaber = 0;
      }
      if (response[0].factura) {
        let tot = 0
        if (response[0].factura.neto) {tot += Math.round(response[0].factura.neto)}
        if (response[0].factura.iva) {tot += Math.round(response[0].factura.iva)}
        if (response[0].factura.ila) {tot += Math.round(response[0].factura.ila)}
        if (response[0].factura.especifico) {tot += Math.round(response[0].factura.especifico)}
        if (response[0].factura.exento) {tot += Math.round(response[0].factura.exento)}
        if (response[0].factura.otros_impuestos) {tot += Math.round(response[0].factura.otros_impuestos)}
        if (response[0].factura.emp_const) {tot -= Math.round(response[0].factura.emp_const)}

        this.total = tot
      }else {
        this.total = 0
      }
   
      this.fecha = response[0].date;
      this.glosa = response[0].glosa;

    },
    
  },
  watch: {
    openDialog(val) {
      if (!val) {
        this.codigo = [];
        this.valor = null;
        this.debe_haber = "";
      } 
    },
    DatosVoucher(val) {
      if (val > 0) {
        this.datos = [];
        this.totalDebe = 0;
        this.totalHaber = 0;
        this.getValuesToEdit();
        
      }
    },
  },
};
</script>
