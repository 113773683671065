import axios from "axios";

const fetchAll = async function (cod_cuenta,empresa) {
    try {
        const response = await axios.get(`/api/list_cuentas/${empresa}/${cod_cuenta}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchAll;