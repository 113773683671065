<template>
  <div>
    <v-card>
      <v-card-title primary-title>
        Asientos
        <!-- <v-spacer></v-spacer>
      <v-btn medium color="primary" disabled> Voucher : 100</v-btn> -->
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" md="3" lg="3">
            <v-form ref="refFormAsiento">
              <v-autocomplete
                return-object
                :items="items_cuentas"
                item-text="infoCompleta"
                item-value="id"
                outlined
                dense
                v-model="codigo"
                label="Seleccione Cuenta"
                :rules="[rules.required]"
              ></v-autocomplete>
              <v-radio-group class="ml-10 pt-0" v-model="debe_haber" dense row>
                <v-radio label="Debe" :value="1"></v-radio>
                <v-radio label="Haber" :value="2"></v-radio>
              </v-radio-group>
              <v-text-field
                v-model="fecha"
                name="fecha"
                dense
                label="Fecha"
                type="date"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                v-model="valor"
                name="valor"
                dense
                label="Valor"
                type="number"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
              <v-textarea
                v-model="glosa"
                rows="1"
                auto-grow
                label="Glosa"
                name="glosa"
                dense
                outlined
                :rules="[rules.required]"
              ></v-textarea>
              <v-col class="text-center">
                <v-btn v-if="!editar" small color="success" @click="AddAsiento">
                  Agregar
                </v-btn>
                <v-btn v-else color="warning" small @click="SaveAsiento">
                  Editar
                </v-btn>
              </v-col>
            </v-form>
          </v-col>
          <v-col cols="12" sm="8" md="9" lg="9">
            <v-row>
              <v-col class="text-center">
                <h4>Total Debe: ${{ formatoNumeroMiles(totalDebe) }}</h4>
              </v-col>
              <v-col class="text-left">
                <h4>Total Haber: ${{ formatoNumeroMiles(totalHaber) }}</h4>
              </v-col>
              <v-col class="text-left">
                <h4>Total : ${{ formatoNumeroMiles(total) }}</h4>
              </v-col>
            </v-row>
            <v-col>
              <v-data-table
                dense
                :headers="headers"
                :items="datos"
                item-key="id"
                class="elevation-10"
                hide-default-footer
                :items-per-page="50"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    color="warning"
                    class="mr-2"
                    @click="EditAsiento(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon small color="red" @click="DeleteAsiento(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
            <v-col class="text-center" v-if="total == 0">
              <v-btn
                v-if="
                  totalDebe == totalHaber && totalDebe != 0 && totalHaber != 0
                "
                small
                color="warning"
                :disabled="isloading"
                :loading="isloading"
                @click="updateVoucher"
                >Guardar</v-btn
              >
            </v-col>
            <v-col class="text-center" v-else>
              <v-btn
                v-if="
                  totalDebe == total &&
                  totalHaber == total &&
                  totalDebe != 0 &&
                  totalHaber != 0
                "
                small
                color="warning"
                :disabled="isloading"
                :loading="isloading"
                @click="updateVoucher"
                >Guardar</v-btn
              >
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import FacturaService from "../service/api/factura";
import LibroDiarioService from "../service/api/libroDiario/index";
import CuentasImputablesService from "../service/api/cuentasImputables/index";
export default {
  data: () => ({
    isloading: false,
    total: 0,
    editar: false,
    voucher_edit: 0,
    id: null,
    id_edit: "",
    codigo: [],
    nombre_cuenta: "",
    cod_largo: 0,
    nombre: "",
    debe_haber: "",
    fecha: "",
    valor: null,
    glosa: "",
    totalDebe: 0,
    totalHaber: 0,
    rules: {
      required: (value) => !!value || "Requerido.",
      emailRule: (value) =>
        /.+@.+\..+/.test(value) || "Correo electrónico inválido.",
      minCharactersPassword: (value) =>
        value.length >= 6 || "Mínimo de 6 caracteres.",
    },
    datos: [],
    items_cuentas: [],
    headers: [
      { text: "Cod", value: "codigo" },
      { text: "Nombre", value: "nombre" },
      { text: "Debe", value: "debe" },
      { text: "Haber", value: "haber" },
      { text: "Glosa", value: "glosa" },
      { text: "Acción", value: "actions", sortable: false },
    ],
  }),
  computed: {
    validateForm() {
      return this.$refs.refFormAsiento.validate();
    },
    resetForm() {
      return this.$refs.refFormAsiento.reset();
    },
  },
  props: {
    openDialog: {
      type: Boolean,
      default: true,
    },
    DatosFactura: {
      type: Array,
      default: null,
    },
    DatosVoucher: {
      type: Array,
      default: null,
    },
  },
  mounted() {
    this.getCuentas();
    // this.getValuesToEdit();
  },
  methods: {
    formatoNumeroMiles(number) {
      if (number || number > 0) {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = "$1.";
        let arr = number.toString().split(".");
        arr[0] = arr[0].replace(exp, rep);
        return arr[1] ? arr.join(".") : arr[0];
      } else return 0;
    },
    async getCuentas() {
      let empresa = parseInt(this.$store.state.empresa);
      let response = await CuentasImputablesService.fetchAll(0, empresa);

      this.items_cuentas = response.map((resp) => {
        resp.infoCompleta = `${resp.cod_largo} - ${resp.nombre_cuenta}`;
        return resp;
      });
    },

    close() {
      this.$emit("snackbarVoucher", this.voucher_edit);
      this.$emit("closeVoucher");
    },
    AddAsiento() {
      if (this.validateForm) {
        let valorDebe = 0;
        let valorHaber = 0;

        if (this.debe_haber == "1") {
          valorDebe = this.valor;
        } else {
          valorHaber = this.valor;
        }
        this.datos.push({
          id: this.id,
          codigo: this.codigo.cod_largo,
          nombre: this.codigo.nombre_cuenta,
          id_cuenta: this.codigo.id,
          debe: valorDebe,
          haber: valorHaber,
          glosa: this.glosa,
          fecha: this.fecha,
          debe_haber: this.debe_haber,
          valor: this.valor,
          cuenta: this.codigo,
          id_empresa: parseInt(this.$store.state.empresa),
        });
        this.totalDebe += parseInt(valorDebe);
        this.totalHaber += parseInt(valorHaber);
        this.id++;
      }
      this.codigo = null;
      if (this.debe_haber == 1) {
        this.debe_haber = 2;
      } else {
        this.debe_haber = 1;
      }
    },
    EditAsiento(item) {
      this.editar = true;
      this.codigo = item.cuenta;
      this.debe_haber = item.debe_haber;
      this.fecha = item.fecha;
      this.valor = item.valor;
      this.glosa = item.glosa;
      this.id_edit = item.id;
    },
    SaveAsiento() {
      let valorDebe = 0;
      let valorHaber = 0;

      if (this.debe_haber == "1") {
        valorDebe = this.valor;
      } else {
        valorHaber = this.valor;
      }
      this.datos.forEach((element) => {
        if (element.id == this.id_edit) {
          element.codigo = this.codigo.cod_largo;
          element.nombre = this.codigo.nombre_cuenta;
          element.id_cuenta = this.codigo.id;
          element.debe_haber = this.debe_haber;
          (element.debe = valorDebe),
            (element.haber = valorHaber),
            (element.fecha = this.fecha);
          element.valor = this.valor;
          element.glosa = this.glosa;
          element.id = this.id_edit;
          return element;
        }
      });
      this.totalDebe = 0;
      this.totalHaber = 0;
      this.datos.forEach((data) => {
        this.totalDebe += parseInt(data.debe);
        this.totalHaber += parseInt(data.haber);
      });
      this.editar = false;
      this.codigo = [];
      this.valor = null;
      if (this.debe_haber == 1) {
        this.debe_haber = 2;
      } else {
        this.debe_haber = 1;
      }
    },
    DeleteAsiento(item) {
      this.datos.forEach(function (asiento, index, object) {
        if (asiento.id === item.id) {
          object.splice(index, 1);
        }
      });
      this.totalDebe = 0;
      this.totalHaber = 0;
      this.datos.forEach((data) => {
        this.totalDebe += parseInt(data.debe);
        this.totalHaber += parseInt(data.haber);
      });
      this.codigo = null;
    },
    async updateVoucher() {
      this.isloading = true
      await FacturaService.update(this.datos, this.voucher_edit[0].voucher);
 
      this.close();
      this.isloading = false
    },
    async getValuesToEdit() {
      if (this.DatosVoucher) {
        this.voucher_edit = this.DatosVoucher;
      }
      let empresa = parseInt(this.$store.state.empresa);
      let response = await LibroDiarioService.fetchByVoucher(
        this.voucher_edit[0].voucher,
        empresa
      );
      if (this.voucher_edit[0].neto != response[0].factura.neto) {
        this.getFacturaValue();
      } else {
        let valorDebe = 0;
        let valorHaber = 0;
        let vmglosa = "";
        for (let i in response) {
          if (response[i].debe_haber == "1") {
            valorDebe = response[i].valor;
          } else {
            valorHaber = response[i].valor;
          }
          if (this.DatosVoucher[0].numero_documento) {
            vmglosa = `Documento: ${this.DatosVoucher[0].numero_documento}`;
          } else {
            vmglosa = `Documento: ${this.DatosVoucher[0].desde} - ${this.DatosVoucher[0].hasta} `;
          }
          this.datos.push({
            id: i,
            codigo: response[i].cuenta.cod_largo,
            nombre: response[i].cuenta.nombre_cuenta,
            debe: valorDebe,
            haber: valorHaber,
            glosa: vmglosa,
            fecha: this.DatosVoucher[0].fecha_factura,
            debe_haber: response[i].debe_haber,
            valor: response[i].valor,
            cuenta: response[i].cuenta,
            id_cuenta: response[i].cuenta.id,
            datos_factura: this.DatosVoucher[0],
            id_factura: this.DatosVoucher[0].id_factura,
            id_empresa: parseInt(this.$store.state.empresa),
          });
          this.totalDebe += parseInt(valorDebe);
          this.totalHaber += parseInt(valorHaber);
          let tot = 0;
          if (this.DatosVoucher[0].neto) {
            tot += parseInt(this.DatosVoucher[0].neto);
          }
          if (this.DatosVoucher[0].iva) {
            tot += parseInt(this.DatosVoucher[0].iva);
          }
          if (this.DatosVoucher[0].ila) {
            tot += parseInt(this.DatosVoucher[0].ila);
          }
          if (this.DatosVoucher[0].especifico) {
            tot += parseInt(this.DatosVoucher[0].especifico);
          }
          if (this.DatosVoucher[0].exento) {
            tot += parseInt(this.DatosVoucher[0].exento);
          }
          if (this.DatosVoucher[0].otros_impuestos) {
            tot += parseInt(this.DatosVoucher[0].otros_impuestos);
          }
          if (this.DatosVoucher[0].emp_const) {
            tot -= parseInt(this.DatosVoucher[0].emp_const);
          }
          this.total = tot;
          valorDebe = 0;
          valorHaber = 0;

          (this.fecha = this.DatosVoucher[0].fecha_factura),
            (this.glosa = vmglosa);
          this.id = i + 1;
        }
      }
    },
    async getFacturaValue() {
      if (this.DatosVoucher[0]) {
        this.datos = [];
        this.fecha = "";
        this.glosa = "";
        this.totalDebe = 0;
        this.totalHaber = 0;
        this.total = 0;
        let tratamiento = this.DatosVoucher[0].tratamientos;
        let response = await CuentasImputablesService.fetchByTratamiento(
          tratamiento
        );

        //   let valorDebe = 0;
        //     let valorHaber = 0;

        //     if (this.debe_haber == "1") {
        //       valorDebe = this.valor;
        //     } else {
        //       valorHaber = this.valor;
        //     }
        for (let i = 0; i < response.length; i++) {
          for (let j = 0; j < response[i].length; j++) {
            let valorDebe = 0;
            let valorHaber = 0;
            let vglosa = "";
            let debeHaberNota = 0;

            if (
              response[i][j].debe_haber == 1 &&
              response[i][j].impuesto.id == 1
            ) {
              if (this.DatosVoucher[0].tipo_documento == "2") {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosVoucher[0].iva) / 100;
              } else {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosVoucher[0].iva) / 100;
              }
            }
            if (
              response[i][j].debe_haber == 2 &&
              response[i][j].impuesto.id == 1
            ) {
              if (this.DatosVoucher[0].tipo_documento == "2") {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosVoucher[0].iva) / 100;
              } else {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosVoucher[0].iva) / 100;
              }
            }
            if (
              response[i][j].debe_haber == 1 &&
              response[i][j].impuesto.id == 2
            ) {
              if (this.DatosVoucher[0].tipo_documento == "2") {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosVoucher[0].ila) / 100;
              } else {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosVoucher[0].ila) / 100;
              }
            }
            if (
              response[i][j].debe_haber == 2 &&
              response[i][j].impuesto.id == 2
            ) {
              if (this.DatosVoucher[0].tipo_documento == "2") {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosVoucher[0].ila) / 100;
              } else {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosVoucher[0].ila) / 100;
              }
            }
            if (
              response[i][j].debe_haber == 1 &&
              response[i][j].impuesto.id == 3
            ) {
              if (this.DatosVoucher[0].tipo_documento == "2") {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosVoucher[0].exento) /
                  100;
              } else {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosVoucher[0].exento) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 2 &&
              response[i][j].impuesto.id == 3
            ) {
              if (this.DatosVoucher[0].tipo_documento == "2") {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosVoucher[0].exento) /
                  100;
              } else {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosVoucher[0].exento) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 1 &&
              response[i][j].impuesto.id == 4
            ) {
              if (this.DatosVoucher[0].tipo_documento == "2") {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje *
                    this.DatosVoucher[0].especifico) /
                  100;
              } else {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje *
                    this.DatosVoucher[0].especifico) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 2 &&
              response[i][j].impuesto.id == 4
            ) {
              if (this.DatosVoucher[0].tipo_documento == "2") {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje *
                    this.DatosVoucher[0].especifico) /
                  100;
              } else {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje *
                    this.DatosVoucher[0].especifico) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 1 &&
              response[i][j].impuesto.id == 5
            ) {
              if (this.DatosVoucher[0].tipo_documento == "2") {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje *
                    this.DatosVoucher[0].otros_impuestos) /
                  100;
              } else {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje *
                    this.DatosVoucher[0].otros_impuestos) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 2 &&
              response[i][j].impuesto.id == 5
            ) {
              if (this.DatosVoucher[0].tipo_documento == "2") {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje *
                    this.DatosVoucher[0].otros_impuestos) /
                  100;
              } else {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje *
                    this.DatosVoucher[0].otros_impuestos) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 1 &&
              response[i][j].impuesto.id == 6
            ) {
              if (this.DatosVoucher[0].tipo_documento == "2") {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosVoucher[0].emp_const) /
                  100;
              } else {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosVoucher[0].emp_const) /
                  100;
              }
            }
            if (
              response[i][j].debe_haber == 2 &&
              response[i][j].impuesto.id == 6
            ) {
              if (this.DatosVoucher[0].tipo_documento == "2") {
                debeHaberNota = 1;
                valorDebe =
                  (response[i][j].porcentaje * this.DatosVoucher[0].emp_const) /
                  100;
              } else {
                debeHaberNota = 2;
                valorHaber =
                  (response[i][j].porcentaje * this.DatosVoucher[0].emp_const) /
                  100;
              }
            }
            if (this.DatosVoucher[0].tipo == "boleta") {
              vglosa = `Boleta: ${this.DatosVoucher[0].desde} - ${this.DatosVoucher[0].hasta}`;
            } else {
              vglosa = `Documento: ${this.DatosVoucher[0].numero_documento}`;
            }
            this.datos.push({
              id: response[i][j].id,
              codigo: response[i][j].cod_largo,
              nombre: response[i][j].nombre_cuenta,
              desde: this.DatosVoucher[0].desde,
              hasta: this.DatosVoucher[0].hasta,
              debe: valorDebe,
              haber: valorHaber,
              glosa: vglosa,
              fecha: this.DatosVoucher[0].fecha_factura,
              debe_haber: debeHaberNota,
              valor: valorDebe + valorHaber,
              cuenta: response[i][j],
              id_cuenta: response[i][j].id,
              estado: this.DatosVoucher[0].tipo,
              datos_factura: this.DatosVoucher[0],
              id_empresa: parseInt(this.$store.state.empresa),
            });
            this.fecha = this.DatosVoucher[0].fecha_factura;
            // debeHaberNota = 0;
            let tot = 0;
            if (this.DatosVoucher[0].neto) {
              tot += parseInt(this.DatosVoucher[0].neto);
            }
            if (this.DatosVoucher[0].iva) {
              tot += parseInt(this.DatosVoucher[0].iva);
            }
            if (this.DatosVoucher[0].ila) {
              tot += parseInt(this.DatosVoucher[0].ila);
            }
            if (this.DatosVoucher[0].especifico) {
              tot += parseInt(this.DatosVoucher[0].especifico);
            }
            if (this.DatosVoucher[0].exento) {
              tot += parseInt(this.DatosVoucher[0].exento);
            }
            if (this.DatosVoucher[0].otros_impuestos) {
              tot += parseInt(this.DatosVoucher[0].otros_impuestos);
            }
            // if (this.DatosVoucher[0].emp_const) {
            //   tot -= parseInt(this.DatosVoucher[0].emp_const);
            // }
            this.total = tot;
            this.totalDebe += parseInt(valorDebe);
            this.totalHaber += parseInt(valorHaber);
          }
        }
        this.fecha = this.DatosVoucher[0].fecha_factura;
        if (this.DatosVoucher[0].tipo == "boleta") {
          this.glosa = `Boleta: ${this.DatosVoucher[0].desde} - ${this.DatosVoucher[0].hasta}`;
        } else {
          this.glosa = `Documento: ${this.DatosVoucher[0].numero_documento}`;
        }

        let tot = 0;
        if (this.DatosVoucher[0].neto) {
          tot += parseInt(this.DatosVoucher[0].neto);
        }
        if (this.DatosVoucher[0].iva) {
          tot += parseInt(this.DatosVoucher[0].iva);
        }
        if (this.DatosVoucher[0].ila) {
          tot += parseInt(this.DatosVoucher[0].ila);
        }
        if (this.DatosVoucher[0].especifico) {
          tot += parseInt(this.DatosVoucher[0].especifico);
        }
        if (this.DatosVoucher[0].exento) {
          tot += parseInt(this.DatosVoucher[0].exento);
        }
        if (this.DatosVoucher[0].otros_impuestos) {
          tot += parseInt(this.DatosVoucher[0].otros_impuestos);
        }
        // if (this.DatosVoucher[0].emp_const) {
        //   tot -= parseInt(this.DatosVoucher[0].emp_const);
        // }
        this.total = tot;
      }
    },
  },
  watch: {
    openDialog(val) {
      if (!val) {
        // this.datos = []
        // this.totalDebe = 0;
        // this.totalHaber = 0;
        this.resetForm;
      }
    },
    DatosVoucher(val) {
      if (val) {
        this.datos = [];
        this.totalDebe = 0;
        this.totalHaber = 0;
        this.getValuesToEdit();
      }
    },
  },
};
</script>
