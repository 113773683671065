<template>
  <v-dialog v-model="openDialog" max-width="1100px" persistent>
    <div class="d-flex flex-row-reverse">
      <v-btn class="mb-1" fab x-small color="red" dark @click="closeDialog"
        >X
      </v-btn>
    </div>
    <v-stepper v-model="ventana">
      <v-stepper-header>
        <v-stepper-step v-if="tipoFactura == 'compra'" step="1" editable>
          Editar Compra
        </v-stepper-step>
        <v-stepper-step v-if="tipoFactura == 'venta'" step="1" editable>
          Editar Venta
        </v-stepper-step>
        <v-stepper-step v-if="tipoFactura == 'boleta'" step="1" editable>
          Editar Boleta
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2"> Editar Asiento </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-row>
              <v-col cols="12" sm="8" md="9" lg="10" fluid>
                <v-form ref="refFormFactura">
                  <v-row>
                    <v-col cols="12" sm="12" md="4" lg="3">
                      <v-text-field
                        v-model="fecha_factura"
                        name="fecha_factura"
                        label="Fecha Factura"
                        outlined
                        dense
                        type="date"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="tipoFactura == 'boleta'"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-text-field
                        v-model="desde_boleta"
                        name="desde_boleta"
                        label="Desde"
                        outlined
                        dense
                        type="number"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="tipoFactura == 'boleta'"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="3"
                    >
                      <v-text-field
                        v-model="hasta_boleta"
                        name="hasta_boleta"
                        label="Hasta"
                        outlined
                        dense
                        type="number"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="tipoFactura != 'boleta'"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                    >
                      <v-text-field
                        v-model="mes_declaracion"
                        name="mes_declaracion"
                        label="Mes Declaración"
                        outlined
                        dense
                        type="month"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="tipoFactura != 'boleta'"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-select
                        label="Tipo Documento"
                        :items="itemsDocuemnto"
                        item-text="nombre_tipo"
                        item-value="id"
                        return-object
                        v-model="tipo_documento"
                        outlined
                        dense
                        :rules="[rules.required]"
                      ></v-select>
                    </v-col>
                    <v-col
                      v-if="tipoFactura == 'compra'"
                      cols="12"
                      sm="8"
                      md="6"
                      lg="4"
                    >
                      <v-select
                        @change="reglaEditarAcreProv"
                        label="Rut - Nombre proveedor"
                        :items="itemsRut"
                        item-text="rut_nombre"
                        item-value="id"
                        v-model="rut_proveedor"
                        outlined
                        dense
                        :rules="[rules.required]"
                      ></v-select>
                    </v-col>
                    <v-col
                      v-if="tipoFactura == 'venta'"
                      cols="12"
                      sm="8"
                      md="6"
                      lg="4"
                    >
                      <v-select
                        @change="reglaEditarAcreProv"
                        label="Rut - Nombre acreedor"
                        :items="itemsRut"
                        item-text="rut_nombre"
                        item-value="id"
                        v-model="rut_proveedor"
                        outlined
                        dense
                        :rules="[rules.required]"
                      ></v-select>
                    </v-col>
                    <v-col
                      v-if="tipoFactura != 'boleta'"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="2"
                    >
                      <v-text-field
                        :disabled="!enableDocumento"
                        v-model="numero_documento"
                        name="numero_documento"
                        label="N° Documento"
                        outlined
                        dense
                        type="number"
                        :rules="[rules.required, rules.register]"
                        :error-messages="ndocRegister"
                      ></v-text-field>
                    </v-col>

                    <!-- <v-col
                      v-if="tipoFactura == 'compra'"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-autocomplete
                        label="Nombre proveedor"
                        :items="itemsNombre"
                        v-model="nombre_proveedor"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col> -->
                    <!-- <v-col
                      v-if="tipoFactura == 'venta'"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-autocomplete
                        label="Nombre acreedor"
                        :items="itemsNombre"
                        v-model="nombre_proveedor"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col> -->

                    <v-col cols="12" sm="6" md="4" lg="3">
                      <v-text-field
                        v-model="neto"
                        name="neto"
                        label="Neto"
                        outlined
                        dense
                        @change="calculoIva()"
                        type="number"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <v-text-field
                        v-model="iva"
                        name="iva"
                        label="Iva"
                        outlined
                        dense
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" v-if="showExento">
                      <v-text-field
                        v-model="exento"
                        name="exento"
                        label="Exento"
                        outlined
                        dense
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="tipoFactura != 'boleta' && showIlla"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-text-field
                        v-model="ila"
                        name="ila"
                        label="Ila"
                        outlined
                        dense
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="tipoFactura != 'boleta' && showEspecifico"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-text-field
                        v-model="especifico"
                        name="especifico"
                        label="Especifico"
                        outlined
                        dense
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="tipoFactura != 'boleta' && showOtros"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-text-field
                        v-model="otros_impuestos"
                        name="otros_impuestos"
                        label="Otros Impuestos"
                        outlined
                        dense
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="tipoFactura == 'venta' && showEmpConst"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-text-field
                        v-model="emp_const"
                        name="emp_const"
                        label="Empresa Constructora"
                        outlined
                        dense
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>

              <v-col cols="12" sm="4" md="3" lg="2">
                <v-card elevation="6">
                  <v-card-title primary-title> Impuestos </v-card-title>
                  <v-card-text>
                    <v-chip-group column multiple>
                      <v-chip filter outlined @click="showExento = !showExento">
                        Exento
                      </v-chip>
                      <v-chip filter outlined @click="showIlla = !showIlla">
                        Illa
                      </v-chip>
                      <v-chip
                        filter
                        outlined
                        @click="showEspecifico = !showEspecifico"
                      >
                        Especifico
                      </v-chip>
                      <v-chip filter outlined @click="showOtros = !showOtros">
                        Otros Impuestos
                      </v-chip>
                      <v-chip
                        v-if="tipoFactura == 'venta'"
                        filter
                        outlined
                        @click="showEmpConst = !showEmpConst"
                      >
                        Emp. Const.
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-row>
              <v-col class="ml-3" cols="2">
                <v-switch
                  label="Con Tratamiento"
                  v-model="switch1"
                  @click="cleanTratamiento"
                ></v-switch>
              </v-col>
              <v-col class="mt-2" cols="12" sm="6" md="4" lg="3" v-if="switch1 == true">
                <v-select
                  v-model="tratamiento"
                  :items="items"
                  item-value="id"
                  item-text="nombre_tratamiento"
                  outlined
                  multiple
                  chips
                  label="Tratamientos"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn color="primary" @click="saveFactura"> Continuar </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <EditAsiento
            :DatosVoucher="datos"
            :openDialog="openDialog"
            @snackbarVoucher="snackbarVoucher"
            @closeVoucher="closeDialog"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>
<script>
import EditAsiento from "../../../components/EditAsientoFactura";
import ProvedorAcreedorService from "../../../service/api/proveedorAcreedor/index";
import TipoDocumentoService from "../../../service/api/tipoDocumento/index";
import TratamientoService from "../../../service/api/tratamiento/index";
import FacturaService from "../../../service/api/factura/index";
export default {
  name: "edita_factura",
  components: {
    EditAsiento,
  },
  props: {
    voucher: {
      type: Number,
      default: null,
    },
  },
  computed: {
    validateForm() {
      return this.$refs.refFormFactura.validate();
    },
    resetForm() {
      return this.$refs.refFormFactura.reset();
    },
  },
  data() {
    return {
      enableDocumento: false,
      id_factura: 0,
      tipoFactura: null,
      switch1: false,
      tratamiento: [],
      showIlla: false,
      showExento: false,
      showEspecifico: false,
      showOtros: false,
      showEmpConst: false,
      itemsDocuemnto: [],
      itemsRut: [],
      itemsNombre: [],
      openDialog: false,
      ventana: 1,
      fecha_factura: null,
      mes_declaracion: null,
      tipo_documento: null,
      rut_proveedor: null,
      nombre_proveedor: null,
      numero_documento: null,
      neto: 0,
      iva: 0,
      exento: 0,
      ila: 0,
      especifico: 0,
      otros_impuestos: 0,
      emp_const: 0,
      desde_boleta: "",
      hasta_boleta: "",
      datos: [],
      ndocRegister: "",
      edit_voucher: null,
      rules: {
        required: (value) => !!value || "Requerido.",
        emailRule: (value) =>
          /.+@.+\..+/.test(value) || "Correo electrónico inválido.",
        minCharactersPassword: (value) =>
          value.length >= 6 || "Mínimo de 6 caracteres.",
        register: () => !this.esRegistrado,
      },
    };
  },
  mounted() {
  
  },
  methods: {
    reglaEditarAcreProv() {
      this.esRegistrado = false;
      this.numero_documento = null;
      this.enableDocumento = true;
    },
    saveFactura() {
      this.datos = [];
      let tipodocto = 0;
      if (this.tipo_documento != null && this.tipo_documento.id) {
        tipodocto = this.tipo_documento.id;
      } else {
        tipodocto = this.tipo_documento;
      }
      this.datos.push({
        fecha_factura: this.fecha_factura,
        mes_declaracion: this.mes_declaracion,
        tipo_documento: tipodocto,
        rut_proveedor: this.rut_proveedor,
        numero_documento: this.numero_documento,
        neto: this.neto,
        iva: this.iva,
        exento: this.exento,
        ila: this.ila,
        especifico: this.especifico,
        otros_impuestos: this.otros_impuestos,
        emp_const: this.emp_const,
        desde: this.desde_boleta,
        hasta: this.hasta_boleta,
        tratamientos: this.tratamiento,
        tipo: this.tipoFactura,
        voucher: this.voucher,
        id_factura: this.id_factura,
      });

      this.validateForm;
      if (this.validateForm) {
        this.ventana = 2;
      }
    },
    closeDialog() {

      this.openDialog = false;
      //   this.resetForm;
      this.ventana = 1;
      this.getValuesToEdit(this.edit_voucher);
      this.esRegistrado = false;
      this.enableDocumento = false;
      //   this.datos = [];
      //   this.tratamiento = [];
      //   this.itemsRut = [];
      //   this.desde_boleta = "";
      //   this.hasta_boleta = "";
      this.$emit('getValues');
    },
    calculoIva() {
      this.iva = Math.round(this.neto * 0.19);
      if (this.showEmpConst == true) {
        this.emp_const = Math.round(this.iva * 0.65);
      }
      this.switch1 = false;
      this.tratamiento = [];
    },
    async getTratamientos() {
      let response = await TratamientoService.fetchCuenta();
      this.items = response;
    },
    async getTipoDocumento() {
      let response = await TipoDocumentoService.fetchAll();
      this.itemsDocuemnto = response;
    },
    async getProvedorAcreedor() {
      let empresa = this.$store.state.empresa;
      let response = await ProvedorAcreedorService.fetchAllFactura(empresa);
      if (this.tipoFactura == "compra") {
        this.itemsRut = response.proveedores.map((resp) => {
          resp.rut_nombre = `${resp.rut} -- ${resp.nombre}`;
          return resp;
        });
      }
      if (this.tipoFactura == "venta") {
        this.itemsRut = response.acreedores.map((resp) => {
          resp.rut_nombre = `${resp.rut} -- ${resp.nombre}`;
          return resp;
        });
      }
    },
    snackbarVoucher(value) {
      this.$emit("snackbarVoucher", value);
    },
    cleanTratamiento() {
      if (this.tratamiento) {
        this.tratamiento = [];
      }
    },
    resetExist() {
      this.rut_proveedor = [];
      this.numero_documento = [];
    },
    async getValuesToEdit(voucher) {
      this.switch1 = false;
      this.tratamiento = [];
      let empresa = this.$store.state.empresa;
      let response = await FacturaService.fetchByVoucher(voucher, empresa);
      if (response[0]) {
        (this.tipoFactura = response[0].tipo_cuenta),
          (this.fecha_factura = response[0].date);
        if (response[0].factura) {
          let date = new Date(response[0].factura.mes_declaracion);
          let mes = new Date();
          if (date.getUTCMonth() + 1 >= 10) {
            mes = date.getUTCMonth() + 1;
          } else {
            mes = "0" + (date.getUTCMonth() + 1);
          }
         
          this.mes_declaracion = date.getUTCFullYear() + "-" + mes;
          this.tipo_documento = parseInt(
            response[0].factura.id_tipo_documentos
          );
          this.rut_proveedor = parseInt(
            response[0].factura.id_proveedoracreedor
          );
          this.numero_documento = response[0].factura.numero_documento;
          this.neto = response[0].factura.neto;
          this.iva = response[0].factura.iva;
          this.exento = response[0].factura.exento;
          this.ila = response[0].factura.ila;
          this.especifico = response[0].factura.especifico;
          this.otros_impuestos = response[0].factura.otros_impuestos;
          this.emp_const = response[0].factura.emp_const;
          this.desde_boleta = response[0].factura.desde;
          this.hasta_boleta = response[0].factura.hasta;
        }
        this.id_factura = response[0].id_factura;
        this.ventana = 1;
        if (response[0].cuenta.id_tratamiento) {
          this.switch1 = true;
          this.tratamiento = response[0].cuenta.id_tratamiento;
        }
      }
    },
  },
  watch: {
    openDialog (val){
      if(val) {
        this.getTratamientos();
        this.getTipoDocumento();
        this.getValuesToEdit(this.voucher);
      }
    },
    tipoFactura(val) {
      if (val) {
        this.getProvedorAcreedor();
      }
    },
    voucher(val) {
      if (val) {
        this.edit_voucher = val;
       
      }
    },
    async numero_documento(val) {
      if (this.enableDocumento) {
        this.esRegistrado = false;
        let data = {};
        data.numero_documento = parseInt(val);
        data.id_provacre = this.rut_proveedor;
        data.tipo_factura = this.tipoFactura;
        data.id_empresa = this.$store.state.empresa;
        data.tipo_documento = this.tipo_documento.id;
        let response = await ProvedorAcreedorService.fetchFolio(data);
        if (response.data.id) {
          this.esRegistrado = true;
          return (this.ndocRegister = `Documento ya existe`);
        } else {
          this.esRegistrado = false;
          return (this.ndocRegister = "");
        }
      }
    },
  },
};
</script>
