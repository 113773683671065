export default [
    {
        title: 'Inicio',
        icon: 'mdi-home-city',
        path: '/'
    },
    {
        title: 'Cuentas Imputables',
        icon: 'mdi-folder',
        path: '/cuentas_imputables'
    },
    {
        title: 'Libro Diario',
        icon: 'mdi-book-open-page-variant',
        path: '/libro_diario'
    },
    {
        title: 'Libro Compra',
        icon: 'mdi-book',
        path: '/libro_compra'
    },
    {
        title: 'Libro Venta',
        icon: 'mdi-book-plus',
        path: '/libro_venta'
    },
    {
        title: 'Boletas',
        icon: 'mdi-book-minus',
        path: '/libro_boleta'
    },
    // {
    //     title: 'Informes',
    //     icon: 'mdi-file-document',
    //     path: '/informes'
    // },
    

]